import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, orderBy } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust this import based on your Firebase setup
import { Card, Button, Modal, Form, Carousel, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const ViewNovels = () => {
    const [novels, setNovels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [currentNovel, setCurrentNovel] = useState(null);
    const [comment, setComment] = useState('');
    const [novelComments, setNovelComments] = useState([]);
    const [purchaseForm, setPurchaseForm] = useState({
        name: '',
        phone: '',
        phone2: '',
        quantity: 1,
        pin: '',
        address: '',
    });
    const navigate = useNavigate();

    useEffect(() => {
        fetchNovels();
    }, []);

    const fetchNovels = async () => {
        setLoading(true);
        try {
            const postsRef = collection(db, 'Posts');
            const q = query(postsRef, where('isNovel', '==', 1));
            const querySnapshot = await getDocs(q);
            const fetchedNovels = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setNovels(fetchedNovels);
        } catch (error) {
            console.error('Error fetching novels:', error);
            swal('Error', 'Failed to fetch novels', 'error');
        }
        setLoading(false);
    };

    const checkAuth = () => {
        const userId = localStorage.getItem('userId');
        const bookStoreId = localStorage.getItem('bookStoreId');
        return userId || bookStoreId;
    };

    const handleBuyClick = (novel) => {
        if (!checkAuth()) {
            navigate('/login');
            return;
        }
        setCurrentNovel(novel);
        setShowPurchaseModal(true);
    };

    const handlePurchaseSubmit = async (e) => {
        e.preventDefault();
        if (!checkAuth()) {
            navigate('/login');
            return;
        }

        try {
            const userId = localStorage.getItem('userId');
            const bookStoreId = localStorage.getItem('bookStoreId');
            const orderData = {
                novelId: currentNovel.id,
                novelName: currentNovel.novelName,
                price: currentNovel.sellingPrice,
                buyerId: userId || bookStoreId,
                buyerType: userId ? 'user' : 'bookstore',
                sellerId: currentNovel.bookStoreId,
                timestamp: new Date(),
                ...purchaseForm,
            };

            await addDoc(collection(db, 'Orders'), orderData);
            swal('Success', 'Novel purchased successfully!', 'success');
            setShowPurchaseModal(false);
            setPurchaseForm({
                name: '',
                phone: '',
                phone2: '',
                quantity: 1,
                pin: '',
                address: '',
            });
        } catch (error) {
            console.error('Error placing order:', error);
            swal('Error', 'Failed to place order', 'error');
        }
    };

    const handleCommentClick = async (novel) => {
        if (!checkAuth()) {
            navigate('/login');
            return;
        }
        setCurrentNovel(novel);
        setShowCommentModal(true);
        
        try {
            const commentsQuery = query(
                collection(db, 'Comments'),
                where('novelId', '==', novel.id),
                orderBy('timestamp', 'desc')
            );
            const commentsSnapshot = await getDocs(commentsQuery);
            const comments = commentsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setNovelComments(comments);
        } catch (error) {
            console.error('Error fetching comments:', error);
            swal('Error', 'Failed to load comments', 'error');
        }
    };

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (!comment.trim()) {
            swal('Error', 'Comment cannot be empty', 'error');
            return;
        }

        try {
            const userId = localStorage.getItem('userId');
            const bookStoreId = localStorage.getItem('bookStoreId');
            const commentData = {
                novelId: currentNovel.id,
                novelName: currentNovel.novelName,
                comment: comment,
                commenterId: userId || bookStoreId,
                commenterType: userId ? 'user' : 'bookstore',
                timestamp: new Date()
            };

            await addDoc(collection(db, 'Comments'), commentData);
            
            // Refresh comments
            const commentsQuery = query(
                collection(db, 'Comments'),
                where('novelId', '==', currentNovel.id),
                orderBy('timestamp', 'desc')
            );
            const commentsSnapshot = await getDocs(commentsQuery);
            const updatedComments = commentsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setNovelComments(updatedComments);
            
            setComment('');
            swal('Success', 'Comment added successfully!', 'success');
        } catch (error) {
            console.error('Error adding comment:', error);
            swal('Error', 'Failed to add comment', 'error');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPurchaseForm(prev => ({ ...prev, [name]: value }));
    };

    const formatDate = (timestamp) => {
        if (timestamp?.toDate) {
            return timestamp.toDate().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        }
        return 'N/A';
    };

    const formatCommenterId = (commenterId) => {
        if (commenterId.length <= 8) return commenterId;
        return `${commenterId.substring(0, 8)}${'*'.repeat(commenterId.length - 8)}`;
    };

    if (loading) {
        return <div className="text-center mt-5">Loading...</div>;
    }

    return (
        <div className="container mt-4">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                {novels.map(novel => (
                    <div key={novel.id} className="col">
                        <Card className="h-100 shadow-sm">
                            <Carousel>
                                {[novel.picture1, novel.picture2, novel.picture3].filter(Boolean).map((pic, index) => (
                                    <Carousel.Item key={index}>
                                        <img
                                            className="d-block w-100"
                                            src={pic}
                                            alt={`${novel.novelName} - ${index + 1}`}
                                            style={{ height: '200px', objectFit: 'cover' }}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                            <Badge 
                                bg="primary"
                                className="vb-badge"
                                >
                                Novel
                            </Badge>
                            <Card.Body className="d-flex flex-column">
                                <Card.Title className="fs-6">{novel.novelName}</Card.Title>
                                <Card.Text className="small mb-0">
                                    Author: {novel.authorName}<br />
                                    Category: {novel.category}<br />
                                    Condition: {novel.novelCondition}<br />
                                    <p className="vb-Category-price">Category/Stream: {novel.stream || 'N/A'}</p>
                                    Year: {novel.publishYear || 'N/A'}
                                </Card.Text>
                                <div className="mt-auto">
                                    <p className="mb-0">
                                        <small>
                                            Original Price <s className="text-muted">{novel.price}</s>{' '}
                                            Rs.<span className="text-success fw-bold">${novel.sellingPrice}</span>
                                        </small>
                                    </p>
                                    <div className="d-flex justify-content-between mt-2">
                                        <Button variant="primary" size="sm" onClick={() => handleBuyClick(novel)}>
                                            Buy
                                        </Button>
                                        <Button variant="secondary" size="sm" onClick={() => handleCommentClick(novel)}>
                                            Comment
                                        </Button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>

            <Modal 
                show={showCommentModal} 
                onHide={() => {
                    setShowCommentModal(false);
                    setNovelComments([]);
                    setComment('');
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Comments - {currentNovel?.novelName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-4">
                        <h6>Add a Comment</h6>
                        <Form onSubmit={handleCommentSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Control 
                                    as="textarea" 
                                    rows={3} 
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    placeholder="Write your comment here..."
                                    required
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Submit Comment
                            </Button>
                        </Form>
                    </div>
                    
                    {novelComments.length > 0 && (
                        <div>
                            <h6>Existing Comments</h6>
                            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                {novelComments.map((comment, index) => (
                                    <Card key={index} className="mb-2">
                                        <Card.Body>
                                            <Card.Text>{comment.comment}</Card.Text>
                                            <Card.Footer className="text-muted">
                                                <small>
                                                    By: {formatCommenterId(comment.commenterId)} ({comment.commenterType})<br />
                                                    {formatDate(comment.timestamp)}
                                                </small>
                                            </Card.Footer>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            <Modal show={showPurchaseModal} onHide={() => setShowPurchaseModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Purchase Novel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handlePurchaseSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="name"
                                value={purchaseForm.name}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control 
                                type="tel" 
                                name="phone"
                                value={purchaseForm.phone}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Alternative Phone (Optional)</Form.Label>
                            <Form.Control 
                                type="tel" 
                                name="phone2"
                                value={purchaseForm.phone2}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control 
                                type="number" 
                                name="quantity"
                                value={purchaseForm.quantity}
                                onChange={handleInputChange}
                                min="1"
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>PIN Code</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="pin"
                                value={purchaseForm.pin}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Address</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3}
                                name="address"
                                value={purchaseForm.address}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Confirm Purchase
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ViewNovels;