
import AdminApp from "./admin/adminapp";
import UserApp from "./user/userapp";
import Security from "./user/security"
function App() {



  if (localStorage.getItem("BookstoreId") == null)
    return (
      <Security>
        <UserApp />
      </Security>
    );
  else
    return (
      <Security>
        <AdminApp />
      </Security>
    );

}

export default App;
