import React, { useState } from 'react';
import { Tabs, Tab, Form, Button } from 'react-bootstrap';
import { collection, addDoc } from 'firebase/firestore';
import { db, storage } from '../firebase'; // Adjust this import based on your Firebase setup
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import swal from 'sweetalert';

const CreateCourseAndNotes = () => {
    const [key, setKey] = useState('uploadNotes');
    const [isUploading, setIsUploading] = useState(false);
    
    const [notesForm, setNotesForm] = useState({
        name: '',
        className: '',
        category: '',
        otherCategory: '',
        pdf1: null,
        pdf2: null,
        collegeName: '',
        description: '',
        price: '',
        sellingPrice: '',
    });

    const [courseForm, setCourseForm] = useState({
        name: '',
        className: '',
        category: '',
        otherCategory: '',
        pdf1: null,
        pdf2: null,
        collegeName: '',
        description: '',
        price: '',
        sellingPrice: '',
    });

    const categories = [
        'HSLC (SEBA)',
        'HSLC (CBSE)',
        'Higher Secondary (Arts)',
        'Higher Secondary (Commerce)',
        'Higher Secondary (Science)',
        "Art's (Degree)",
        'Commerce (Degree)',
        'Science (Degree)',
        "Art's (Master Degree)",
        'Commerce (Master Degree)',
        'Science (Master Degree)',
        'Other',
    ];

    const handleNotesChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            const file = files[0];
            if (file && file.type === 'application/pdf') {
                setNotesForm(prev => ({ ...prev, [name]: file }));
            } else {
                swal('Error', 'Please upload only PDF files.', 'error');
            }
        } else {
            setNotesForm(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleCourseChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            const file = files[0];
            if (file && file.type === 'application/pdf') {
                setCourseForm(prev => ({ ...prev, [name]: file }));
            } else {
                swal('Error', 'Please upload only PDF files.', 'error');
            }
        } else {
            setCourseForm(prev => ({ ...prev, [name]: value }));
        }
    };

    const uploadFile = async (file) => {
        if (!file) return null;
        const storageRef = ref(storage, `uploads/${Date.now()}_${file.name}`);
        await uploadBytes(storageRef, file);
        return getDownloadURL(storageRef);
    };

    const uploadNotes = async (e) => {
        e.preventDefault();
        if (!notesForm.name || !notesForm.category) {
            swal('Error', 'Name and category are mandatory fields', 'error');
            return;
        }
        if (!notesForm.pdf1 || !notesForm.pdf2) {
            swal('Error', 'Please upload at least 2 PDFs', 'error');
            return;
        }
        setIsUploading(true);
        try {
            const userId = localStorage.getItem('userId');
            const userName = localStorage.getItem('userName');

            const pdf1Url = await uploadFile(notesForm.pdf1);
            const pdf2Url = await uploadFile(notesForm.pdf2);

            await addDoc(collection(db, 'Posts'), {
                ...notesForm,
                pdf1: pdf1Url,
                pdf2: pdf2Url,
                userId,
                userName,
                isNote: 1,
                category: notesForm.category === 'Other' ? notesForm.otherCategory : notesForm.category,
                timestamp: new Date(),
            });
            setIsUploading(false);
            swal('Success', 'Notes uploaded successfully', 'success');
            setNotesForm({
                name: '',
                className: '',
                category: '',
                otherCategory: '',
                pdf1: null,
                pdf2: null,
                collegeName: '',
                description: '',
                price: '',
                sellingPrice: '',
            });
        } catch (error) {
            setIsUploading(false);
            swal('Error', 'Failed to upload notes', 'error');
            console.error('Error uploading notes:', error);
        }
    };

    const uploadCourseMaterials = async (e) => {
        e.preventDefault();
        if (!courseForm.name || !courseForm.category) {
            swal('Error', 'Name and category are mandatory fields', 'error');
            return;
        }
        if (!courseForm.pdf1 || !courseForm.pdf2) {
            swal('Error', 'Please upload at least 2 PDFs', 'error');
            return;
        }
        setIsUploading(true);
        try {
            const userId = localStorage.getItem('userId');
            const userName = localStorage.getItem('userName');

            const pdf1Url = await uploadFile(courseForm.pdf1);
            const pdf2Url = await uploadFile(courseForm.pdf2);

            await addDoc(collection(db, 'Posts'), {
                ...courseForm,
                pdf1: pdf1Url,
                pdf2: pdf2Url,
                userId,
                userName,
                isCourse: 1,
                category: courseForm.category === 'Other' ? courseForm.otherCategory : courseForm.category,
                timestamp: new Date(),
            });
            setIsUploading(false);
            swal('Success', 'Course materials uploaded successfully', 'success');
            setCourseForm({
                name: '',
                className: '',
                category: '',
                otherCategory: '',
                pdf1: null,
                pdf2: null,
                collegeName: '',
                description: '',
                price: '',
                sellingPrice: '',
            });
        } catch (error) {
            setIsUploading(false);
            swal('Error', 'Failed to upload course materials', 'error');
            console.error('Error uploading course materials:', error);
        }
    };

    return (
        <div className="container mt-4">
            <h2 className="text-center mb-4">Upload Course Materials / Notes</h2>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="uploadNotes" title="Upload Notes">
                    <Form onSubmit={uploadNotes}>
                        <Form.Group className="mb-3">
                            <Form.Label>Note Name *</Form.Label>
                            <Form.Control type="text" name="name" value={notesForm.name} onChange={handleNotesChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Class Name</Form.Label>
                            <Form.Control type="text" name="className" value={notesForm.className} onChange={handleNotesChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Category *</Form.Label>
                            <Form.Select name="category" value={notesForm.category} onChange={handleNotesChange} required>
                                <option value="">Select Category</option>
                                {categories.map((cat) => (
                                    <option key={cat} value={cat}>
                                        {cat}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        {notesForm.category === 'Other' && (
                            <Form.Group className="mb-3">
                                <Form.Label>Other Category *</Form.Label>
                                <Form.Control type="text" name="otherCategory" value={notesForm.otherCategory} onChange={handleNotesChange} required />
                            </Form.Group>
                        )}
                        <Form.Group className="mb-3">
                            <Form.Label>Upload PDFs (2 required) *</Form.Label>
                            <Form.Control type="file" name="pdf1" onChange={handleNotesChange} accept="application/pdf" required className="mb-2" />
                            <Form.Control type="file" name="pdf2" onChange={handleNotesChange} accept="application/pdf" required className="mb-2" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Price *</Form.Label>
                            <Form.Control 
                                type="number" 
                                name="price" 
                                value={notesForm.price} 
                                onChange={handleNotesChange} 
                                placeholder="Enter price in INR"
                                required 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Sell price</Form.Label>
                            <Form.Control 
                                type="number" 
                                name="sellingPrice" 
                                value={notesForm.sellingPrice} 
                                onChange={handleNotesChange} 
                                placeholder="Enter Sell Price"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>College Name</Form.Label>
                            <Form.Control type="text" name="collegeName" value={notesForm.collegeName} onChange={handleNotesChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} name="description" value={notesForm.description} onChange={handleNotesChange} />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={isUploading}>
                            {isUploading ? 'Uploading...' : 'Upload Notes'}
                        </Button>
                    </Form>
                </Tab>

                <Tab eventKey="uploadCourseMaterials" title="Upload Course Materials">
                    <Form onSubmit={uploadCourseMaterials}>
                        <Form.Group className="mb-3">
                            <Form.Label>Course Name *</Form.Label>
                            <Form.Control type="text" name="name" value={courseForm.name} onChange={handleCourseChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Class Name</Form.Label>
                            <Form.Control type="text" name="className" value={courseForm.className} onChange={handleCourseChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Category *</Form.Label>
                            <Form.Select name="category" value={courseForm.category} onChange={handleCourseChange} required>
                                <option value="">Select Category</option>
                                {categories.map((cat) => (
                                    <option key={cat} value={cat}>
                                        {cat}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        {courseForm.category === 'Other' && (
                            <Form.Group className="mb-3">
                                <Form.Label>Other Category *</Form.Label>
                                <Form.Control type="text" name="otherCategory" value={courseForm.otherCategory} onChange={handleCourseChange} required />
                            </Form.Group>
                        )}
                        <Form.Group className="mb-3">
                            <Form.Label>Upload PDFs (2 required) *</Form.Label>
                            <Form.Control type="file" name="pdf1" onChange={handleCourseChange} accept="application/pdf" required className="mb-2" />
                            <Form.Control type="file" name="pdf2" onChange={handleCourseChange} accept="application/pdf" required className="mb-2" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Original Price *</Form.Label>
                            <Form.Control 
                                type="number" 
                                name="price" 
                                value={courseForm.price} 
                                onChange={handleCourseChange} 
                                placeholder="Enter price in INR"
                                required 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Sell Price</Form.Label>
                            <Form.Control 
                                type="number" 
                                name="sellingPrice" 
                                value={courseForm.sellingPrice} 
                                onChange={handleCourseChange} 
                                placeholder="Enter Sell price"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>College Name</Form.Label>
                            <Form.Control type="text" name="collegeName" value={courseForm.collegeName} onChange={handleCourseChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} name="description" value={courseForm.description} onChange={handleCourseChange} />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={isUploading}>
                            {isUploading ? 'Uploading...' : 'Upload Course'}
                        </Button>
                    </Form>
                </Tab>
            </Tabs>
            {isUploading && (
                <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
                    <div className="bg-white p-4 rounded">
                        <p className="mb-0">Uploading... Please wait</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateCourseAndNotes;