import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";
import { Camera, BookOpen, GraduationCap, Store, Star, Book } from "lucide-react";
import swal from "sweetalert";
import TestimonialCards from "./testimonial";
const features = [
  {
    title: "Post Used Books",
    description: "List and sell your used textbooks easily. Create a sustainable marketplace for affordable academic resources.",
    icon: BookOpen,
  },
  {
    title: "Request a Book",
    description: "Students can request a book and our team will find the book for you.",
    icon: Book,
  },
  {
    title: "Share Study Notes",
    description: "Turn your notes into valuable resources. Help peers while earning from your study materials.",
    icon: GraduationCap,
  },
  {
    title: "Bookstore Integration",
    description: "Connect with local bookstores. Browse and buy from their inventory directly through our platform.",
    icon: Store,
  },
  {
    title: "Create Online Courses",
    description: "Share your knowledge through engaging online courses. Reach students globally and foster peer learning.",
    icon: Camera,
  },
  {
    title: "Quality Ratings",
    description: "Choose from multiple condition options. Our rating system ensures fair pricing and transparency.",
    icon: Star,
  },
];

const ContactUs = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [reason, setReason] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "contactus"), {
        name,
        phone,
        reason,
        timestamp: new Date(),
      });
      swal({
        title: "Success!",
        text: "Thanks for your feedback!",
        icon: "success",
        button: "OK",
      });
      setName("");
      setPhone("");
      setReason("");
    } catch (error) {
      swal({
        title: "Error!",
        text: "There was an error submitting your message. Please try again.",
        icon: "error",
        button: "OK",
      });
      console.error("Error storing contact us data:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-indigo-200 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            Contact Us
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Get in touch with our team for any questions or support.
          </p>
        </div>

        <div className="mt-10">
          <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.title} className="pt-6">
                <div className="flow-root bg-white rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {feature.title}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-20 bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Contact Form</h3>
            <div className="mt-5">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <br></br>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <br></br>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Your phone number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="reason" className="block text-sm font-medium text-gray-700">
                    Reason for Contact
                  </label>
                  <br></br>
                  <textarea
                    id="reason"
                    name="reason"
                    rows={4}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Tell us how we can help you"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <TestimonialCards/>
    </div>
  );
};

export default ContactUs;