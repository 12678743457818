import React, { useState, useEffect } from "react";
import { getDocs, query, collection, where, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; // Assuming firebase is setup correctly
import './Orders.css'; // Add styles for the orders and modal

const Orders = () => {
    const userId = localStorage.getItem('userId');
    // const userName = localStorage.getItem('userName');
    const [orders, setOrders] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null); // Store the selected book/novel's data
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false); // To control modal visibility

    // Fetch Orders for the current bookstore
    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const ordersQuery = query(
                    collection(db, "Orders"),
                    where("sellerId", "==", userId)
                );
                const querySnapshot = await getDocs(ordersQuery);

                const fetchedOrders = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setOrders(fetchedOrders);
            } catch (error) {
                console.error("Error fetching orders: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, [userId]);

    // Fetch details from Posts or Novels based on order type
    const fetchItemDetails = async (itemId, isNovel) => {
        try {
            console.log(itemId, isNovel);
            
            const collectionName = "Posts";
            const itemRef = doc(db, collectionName, itemId);
            const itemSnap = await getDoc(itemRef);

            if (itemSnap.exists()) {
                setSelectedItem(itemSnap.data());
                setShowModal(true); // Open the modal when book/novel data is fetched
            } else {
                console.log("No such item!");
            }
        } catch (error) {
            console.error("Error fetching item details: ", error);
        }
    };

    // Close the modal
    const closeModal = () => {
        setShowModal(false);
        setSelectedItem(null); // Reset the selected item data
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="orders-container">
            <h2>Orders</h2>
            <div className="orders-list">
                {orders.length > 0 ? (
                    orders.map((order) => (
                        <div key={order.id} className="order-card">
                            <div>
                                <strong>Name:</strong> {order.name}
                            </div>
                            {/* <div>
                                <strong>Address:</strong> {order.address}
                            </div>
                            <div>
                                <strong>Phone:</strong> {order.phone}, {order.phone2}
                            </div> */}
                            <div>
                                <strong>PIN:</strong> {order.pin}
                            </div>
                            <div>
                                {/* Check if it's a book or a novel */}
                                {order.bookId ? (
                                    <>
                                        <strong>Book Name:</strong>{" "}
                                        <span className="clickable" onClick={() => fetchItemDetails(order.bookId, false)}>
                                            {order.bookName}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <strong>Novel Name:</strong>{" "}
                                        <span className="clickable" onClick={() => fetchItemDetails(order.novelId, 1)}>
                                            {order.novelName}
                                        </span>
                                    </>
                                )}
                            </div>
                            <div>
                                <strong>Price:</strong> ₹{order.price}
                            </div>
                            <div>
                                <strong>Quantity:</strong> {order.quantity}
                            </div>
                            <div>
                                <strong>Total:</strong> ₹{order.price * order.quantity}
                            </div>
                            <div>
                                <strong>Order Date:</strong> {new Date(order.timestamp.seconds * 1000).toLocaleDateString()}
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No orders found</div>
                )}
            </div>

            {/* Modal to show item (book/novel) details */}
            {showModal && selectedItem && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close-btn" onClick={closeModal}>
                            &times;
                        </span>
                        {/* Conditionally render book or novel details */}
                        {selectedItem.bookName ? (
                            <>
                                <h3>{selectedItem.bookName}</h3>
                                <div>
                                    <strong>Author Name:</strong> {selectedItem.authorName}
                                </div>
                                <div>
                                    <strong>Condition:</strong> {selectedItem.bookCondition}
                                </div>
                                <div>
                                    <strong>Class Name:</strong> {selectedItem.className}
                                </div>
                                <div>
                                    <strong>Sell Price:</strong> {selectedItem.sellingPrice}rs
                                </div>
                                <div>
                                    <strong>Price:</strong> ₹{selectedItem.price}
                                </div>
                                <div>
                                    <strong>Published Year:</strong> {selectedItem.publishYear}
                                </div>
                                <div className="book-images">
                                    {selectedItem.picture1 && (
                                        <img src={selectedItem.picture1} alt="Book" width="100" height="100" />
                                    )}
                                    {selectedItem.picture2 && (
                                        <img src={selectedItem.picture2} alt="Book" width="100" height="100" />
                                    )}
                                    {selectedItem.picture3 && (
                                        <img src={selectedItem.picture3} alt="Book" width="100" height="100" />
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <h3>{selectedItem.novelName}</h3>
                                <div>
                                    <strong>Author:</strong> {selectedItem.authorName || "N/A"}
                                </div>
                                <div>
                                    <strong>Published Year:</strong> {selectedItem.publishYear || "N/A"}
                                </div>
                                <div>
                                    <strong>Price:</strong> ₹{selectedItem.price}
                                </div>
                                <div>
                                    <strong>Summary:</strong> {selectedItem.summary || "N/A"}
                                </div>
                                <div className="book-images">
                                    {selectedItem.picture1 && (
                                        <img src={selectedItem.picture1} alt="Novel" width="100" height="100" />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Orders;