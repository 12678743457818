import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, Modal, Form,Badge } from 'react-bootstrap';
import { collection, query, orderBy, limit, startAfter, getDocs, where, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import './ViewBooks.css';
const ViewNotes = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [currentPdf, setCurrentPdf] = useState('');
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [currentNote, setCurrentNote] = useState(null);
  const [comment, setComment] = useState('');
  const [noteComments, setNoteComments] = useState([]);
  const [purchaseForm, setPurchaseForm] = useState({
    name: '',
    phone: '',
    phone2: '',
    quantity: 1,
    pin: '',
    address: '',
  });

  // Group posts into sets of ITEMS_PER_SLIDE
  const ITEMS_PER_SLIDE = 12;
  const groupedPosts = posts.reduce((acc, post, index) => {
    const groupIndex = Math.floor(index / ITEMS_PER_SLIDE);
    if (!acc[groupIndex]) acc[groupIndex] = [];
    acc[groupIndex].push(post);
    return acc;
  }, []);


  const navigate = useNavigate();
  const POSTS_PER_PAGE = 12;

  const fetchPosts = useCallback(async () => {
    if (!hasMore) return;

    setLoading(true);
    try {
      let postsQuery = query(
        collection(db, 'Posts'),
        where('isNote', "==", 1),
        orderBy('timestamp', 'desc'),
        limit(POSTS_PER_PAGE)
      );

      if (lastVisible) {
        postsQuery = query(postsQuery, startAfter(lastVisible));
      }

      const querySnapshot = await getDocs(postsQuery);
      const newPosts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setPosts(prevPosts => {
        const updatedPosts = [...prevPosts, ...newPosts];
        const uniquePosts = updatedPosts.filter((post, index, self) =>
          index === self.findIndex((t) => t.id === post.id)
        );
        return uniquePosts;
      });

      const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisible(lastVisibleDoc);
      setHasMore(querySnapshot.docs.length === POSTS_PER_PAGE);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  }, [lastVisible, hasMore]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);


  const formatDate = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      return timestamp.toDate().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
    return 'N/A';
  };

  const handlePreview = (pdfUrl) => {
    setCurrentPdf(pdfUrl);
    setShowPdfModal(true);
  };

  const PdfPreview = ({ pdfUrl }) => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    if (isMobile) {
      return (
        <div style={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true&rm=minimal`}
            width="100%"
            height="100%"
            style={{ border: 'none', position: 'absolute', top: 0, left: 0 }}
            title="PDF Preview"
            sandbox="allow-scripts allow-same-origin"
          />
          <div 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1000,
              pointerEvents: 'none'
            }}
          />
        </div>
      );
    } else {
      return (
        <object
          data={`${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`}
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <p>Your browser does not support PDFs. Please try different browser to view it: 
            {/* <a href={pdfUrl}>Download PDF</a> */}
          </p>
        </object>
      );
    }
  };

  const checkAuth = () => {
    const userId = localStorage.getItem('userId');
    const bookStoreId = localStorage.getItem('bookStoreId');
    return userId || bookStoreId;
  };

  const handleBuyClick = (note) => {
    if (!checkAuth()) {
      navigate('/login');
      return;
    }
    setCurrentNote(note);
    setShowPurchaseModal(true);
  };

  const handlePurchaseSubmit = async (e) => {
    e.preventDefault();
    if (!checkAuth()) {
      navigate('/login');
      return;
    }

    try {
      const userId = localStorage.getItem('userId');
      const bookStoreId = localStorage.getItem('bookStoreId');
      const orderData = {
        noteId: currentNote.id,
        noteName: currentNote.name,
        price: currentNote.price,
        buyerId: userId || bookStoreId,
        buyerType: userId ? 'user' : 'bookstore',
        sellerId: currentNote.userId,
        timestamp: new Date(),
        ...purchaseForm,
      };

      await addDoc(collection(db, 'Orders'), orderData);
      swal('Success', 'Note purchased successfully!', 'success');
      setShowPurchaseModal(false);
      setPurchaseForm({
        name: '',
        phone: '',
        phone2: '',
        quantity: 1,
        pin: '',
        address: '',
      });
    } catch (error) {
      console.error('Error placing order:', error);
      swal('Error', 'Failed to place order', 'error');
    }
  };

  const handleCommentClick = async (note) => {
    if (!checkAuth()) {
      navigate('/login');
      return;
    }
    setCurrentNote(note);
    setShowCommentModal(true);
    
    try {
      const commentsQuery = query(
        collection(db, 'Comments'),
        where('noteId', '==', note.id),
        orderBy('timestamp', 'desc')
      );
      const commentsSnapshot = await getDocs(commentsQuery);
      const comments = commentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setNoteComments(comments);
    } catch (error) {
      console.error('Error fetching comments:', error);
      swal('Error', 'Failed to load comments', 'error');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!comment.trim()) {
      swal('Error', 'Comment cannot be empty', 'error');
      return;
    }

    try {
      const userId = localStorage.getItem('userId');
      const bookStoreId = localStorage.getItem('bookStoreId');
      const commentData = {
        noteId: currentNote.id,
        noteName: currentNote.name,
        comment: comment,
        commenterId: userId || bookStoreId,
        commenterType: userId ? 'user' : 'bookstore',
        timestamp: new Date()
      };

      await addDoc(collection(db, 'Comments'), commentData);
      
      // Refresh comments
      const commentsQuery = query(
        collection(db, 'Comments'),
        where('noteId', '==', currentNote.id),
        orderBy('timestamp', 'desc')
      );
      const commentsSnapshot = await getDocs(commentsQuery);
      const updatedComments = commentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setNoteComments(updatedComments);
      
      setComment('');
      swal('Success', 'Comment added successfully!', 'success');
    } catch (error) {
      console.error('Error adding comment:', error);
      swal('Error', 'Failed to add comment', 'error');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPurchaseForm(prev => ({ ...prev, [name]: value }));
  };

  const formatCommenterId = (commenterId) => {
    if (commenterId.length <= 8) return commenterId;
    return `${commenterId.substring(0, 8)}${'*'.repeat(commenterId.length - 8)}`;
  };

  if (loading && posts.length === 0) {
    return <div className="text-center mt-5">Loading posts...</div>;
  }

  return (
<div className="vb-container">
    <div className="vb-scroll-wrapper">
      {posts.map((post) => (
        <div key={post.id} className="vb-card-wrapper">
          <Card className="vb-book-card">
                <Badge  bg="primary" className="vb-badge" >
                  Notes
                </Badge>
            <Card.Body>
              <Card.Title>{post.name}</Card.Title>
              <div className="vb-book-info">
                <p>Author: {post.userName}</p>
                <p>Class: {post.className || 'N/A'}</p>
                <p>Category: {post.category}</p>
              </div>
              <div className="vb-price-info">
                <p>
                  <span className="vb-selling-price">₹{post.price}</span>
                </p>
                <p className="vb-post-date">
                  Posted: {formatDate(post.timestamp)}
                </p>
              </div>
              <div className="vb-button-group">
                <Button variant="primary" size="sm" onClick={() => handlePreview(post.pdf1)}>
                  Preview
                </Button>
                <Button variant="primary" size="sm" onClick={() => handleBuyClick(post)}>
                  Buy
                </Button>
                <Button variant="secondary" size="sm" onClick={() => handleCommentClick(post)}>
                  Comment
                </Button>
              </div>
            </Card.Body>
            <Card.Footer>
              College: {post.collegeName}
            </Card.Footer>
          </Card>
        </div>
      ))}
    </div>

      <Modal show={showPdfModal} onHide={() => setShowPdfModal(false)} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>PDF Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <PdfPreview pdfUrl={currentPdf} />
        </Modal.Body>
      </Modal>

      <Modal 
        show={showCommentModal} 
        onHide={() => {
          setShowCommentModal(false);
          setNoteComments([]);
          setComment('');
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comments - {currentNote?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <h6>Add a Comment</h6>
            <Form onSubmit={handleCommentSubmit}>
              <Form.Group className="mb-3">
                <Form.Control 
                  as="textarea" 
                  rows={3} 
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Write your comment here..."
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit Comment
              </Button>
            </Form>
          </div>
          
          {noteComments.length > 0 && (
            <div>
              <h6>Existing Comments</h6>
              <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {noteComments.map((comment, index) => (
                  <Card key={index} className="mb-2">
                    <Card.Body>
                      <Card.Text>{comment.comment}</Card.Text>
                      <Card.Footer className="text-muted">
                        <small>
                          By: {formatCommenterId(comment.commenterId)} ({comment.commenterType})<br />
                          {formatDate(comment.timestamp)}
                        </small>
                      </Card.Footer>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showPurchaseModal} onHide={() => setShowPurchaseModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Purchase Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handlePurchaseSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control 
                type="text" 
                name="name"
                value={purchaseForm.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control 
                type="tel" 
                name="phone"
                value={purchaseForm.phone}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Alternative Phone (Optional)</Form.Label>
              <Form.Control 
                type="tel" 
                name="phone2"
                value={purchaseForm.phone2}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control 
                type="number" 
                name="quantity"
                value={purchaseForm.quantity}
                onChange={handleInputChange}
                min="1"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>PIN Code</Form.Label>
              <Form.Control 
                type="text" 
                name="pin"
                value={purchaseForm.pin}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control 
                as="textarea" 
                rows={3}
                name="address"
                value={purchaseForm.address}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Confirm Purchase
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewNotes;