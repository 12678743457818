import { useEffect, useState } from "react";
import { HashRouter, Routes, Route, Link } from "react-router-dom";
import Mylogin from './login';
import AdminSignUp from "../admin/AdminSignup";
import Adminlogin from "../admin/Adminlogin";
import UserSignUp from "./UserSignUp";
// import MyBookings from "./MyBookins";
import './userapp.css';
import logo from '../logo.png';
import TabsComponent from "./Tabs";
import ContactUs from "./privacyPolicy";
// import AdminLogin from "./siteAdminDashboard";
import CreateBooksAndNovels from "./CreateBooksAndNovels";
import CreateCourseAndNotes from "./CreateCourseAndNotes";
import AlterAllPosts from "./AlterAllPost";
import Orders from "./orderlist";
const RotatingText = () => {
    const texts = ["Note's", "Novels", "Courses", "Books", "Materials",];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % texts.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [texts.length]);

    return (
        <div className="rotating-text">
            <div className="text">{texts[currentIndex]}</div>
        </div>
    );
};

const UserApp = () => {
    const isLoggedIn = localStorage.getItem("userName") !== null;
    const [showAccountDropdown, setShowAccountDropdown] = useState(false);
    const [showStoreDropdown, setShowStoreDropdown] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem("userName");
        localStorage.removeItem("UserPhone");
        window.location.reload();
        window.location.href = ('/');
    };

    const toggleAccountDropdown = () => {
        setShowAccountDropdown(prev => !prev);
        setShowStoreDropdown(false); // Close store dropdown if it's open
    };

    const toggleStoreDropdown = () => {
        setShowStoreDropdown(prev => !prev);
        setShowAccountDropdown(false); // Close account dropdown if it's open
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const accountDropdown = document.querySelector('.account-dropdown');
            const storeDropdown = document.querySelector('.store-dropdown');
            const navbarToggleButton = document.querySelector('.navbar-toggler');

            if (accountDropdown && !accountDropdown.contains(event.target) && !navbarToggleButton.contains(event.target)) {
                setShowAccountDropdown(false);
            }
            if (storeDropdown && !storeDropdown.contains(event.target) && !navbarToggleButton.contains(event.target)) {
                setShowStoreDropdown(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        window.addEventListener('scroll', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
            window.removeEventListener('scroll', handleClickOutside);
        };
    }, []);

    return (
        <HashRouter>
            <nav className="navbar navbar-expand-sm navbar-dark bg-dark p-1 sticky-top">
                <div className="container">
                    <div className="navbar-brand">
                        <img src={logo} style={{ width: "60px", height: "45px" }} alt="Logo" />StudyHub<RotatingText />
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="mynavbar">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item me-4">
                                <Link className="nav-link active" to="/"> <i className="material-icons">home</i> Home </Link>
                            </li>
                            {isLoggedIn && (
                                <li className="nav-item me-4">
                                    <Link className="nav-link active" to="/AlterBooksAndNovels"><i className="material-icons">backup_table</i> My Posts </Link>
                                </li>
                            )}
                            {isLoggedIn && (
                                <li className="nav-item me-4">
                                    <Link className="nav-link active" to="/CreateBooksAndNovels"> <i className="material-icons">menu_book</i> Books/ Novels </Link>
                                </li>
                            )}
                           {isLoggedIn && (
                            <li className="nav-item me-4">
                                <Link className="nav-link active" to="/userOrders"> <i className="material-icons"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-80 92L160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11Zm200-528 77-44-237-137-78 45 238 136Zm-160 93 78-45-237-137-78 45 237 137Z"/></svg></i> Orders </Link>
                             </li>
                            )}
                            {isLoggedIn && (
                                <li className="nav-item me-4">
                                    <Link className="nav-link active" to="/CreateCourseAndNotes"> <i className="material-icons">upload</i> Course/ Notes </Link>
                                </li>
                            )}
                            <li className="nav-item dropdown me-4 account-dropdown" onClick={toggleAccountDropdown}>
                                <div className="nav-link dropdown-toggle active" id="accountDropdown" role="button" aria-expanded={showAccountDropdown}>
                                    <i className="material-icons">account_circle</i> Student
                                </div>
                                {showAccountDropdown && (
                                    <ul className="dropdown-menu show" aria-labelledby="accountDropdown">
                                        {isLoggedIn ? (
                                            <li>
                                                <button className="dropdown-item" onClick={handleLogout}>
                                                    Logout
                                                </button>
                                            </li>
                                        ) : (
                                            <>
                                                <li><Link className="dropdown-item" to="/login">Student Login</Link></li>
                                                <li><Link className="dropdown-item" to="/UserSignUp">Student Sign Up</Link></li>
                                            </>
                                        )}
                                    </ul>
                                )}
                            </li>


                            {!isLoggedIn && (
                                <li className="nav-item dropdown me-4 store-dropdown" onClick={toggleStoreDropdown}>
                                    <div className="nav-link dropdown-toggle active" id="storeDropdown" role="button" aria-expanded={showStoreDropdown}>
                                        <i className="material-icons">store</i> Store
                                    </div>
                                    {showStoreDropdown && (
                                        <ul className="dropdown-menu show" aria-labelledby="storeDropdown">
                                            <li><Link className="dropdown-item" to="/slogin">Book Store Login</Link></li>
                                            <li><Link className="dropdown-item" to="/sSignup">Book Store SignUp</Link></li>
                                        </ul>
                                    )}
                                </li>
                            )}
                            <li className="nav-item me-4">
                                <Link className="nav-link active" to="/ContactUs"> <i className=""></i> Contact Us </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <Routes>
                <Route exact path="/" element={<TabsComponent />} />
                <Route exact path="/login" element={<Mylogin />} />
                <Route exact path="/slogin" element={<Adminlogin />} />
                <Route exact path="/UserSignUp" element={<UserSignUp />} />
                <Route exact path="/sSignup" element={<AdminSignUp />} />
                {/* <Route exact path="/AdminLogin" element={<AdminLogin />} /> */}
                <Route exact path="/ContactUs" element={<ContactUs />} />
                {isLoggedIn && <Route exact path="/AlterBooksAndNovels" element={<AlterAllPosts />} />}
                {isLoggedIn && <Route exact path="/CreateBooksAndNovels" element={<CreateBooksAndNovels />} />}
                {isLoggedIn && <Route exact path="/CreateCourseAndNotes" element={<CreateCourseAndNotes />} />}
                {isLoggedIn && <Route exact path="/userOrders" element={<Orders />} />}
            </Routes>

            <footer className="bg-mycolor text-white p-5 mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h4>About Us</h4>
                            <p>
                                StudyHub is a dynamic online platform designed to bridge the gap between students and sellers in the educational marketplace. This innovative website allows students to read and purchase a diverse range of books and novels, as well as access valuable study materials and notes tailored to their academic needs. For those looking to sell, StudyHub provides an easy-to-use interface where users can post listings for books, novels, and courses, allowing them to share their knowledge and resources with others.
                            </p>
                            <p>
                                Whether you’re a student seeking to enhance your learning experience or a seller looking to reach a broader audience, StudyHub fosters a vibrant community centered around education and shared learning resources.
                            </p>
                        </div>
                        <div className="col-lg-4">
                            <h4>Contact Support</h4>
                            <p>Mobile: +91-9365919522</p>
                            <p>Email: sarifkhanngn02@gmail.com</p>
                        </div>
                        <div className="col-lg-4">
                            <h4>Follow Us on Social Media</h4>
                            <p><i className="fab fa-facebook fa-lg"></i> www.facebook.com</p>
                            <p><i className="fab fa-twitter fa-lg"></i> www.twitter.com</p>
                            <p><i className="fab fa-linkedin fa-lg"></i> www.linkedin.com</p>
                        </div>
                    </div>
                </div>
            </footer>
        </HashRouter>
    );
};

export default UserApp;