import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import swal from 'sweetalert';

const streamOptions = [
  "Arts", "Science", "Commerce", "Humanities", "Engineering",
  "Medical", "Law", "Business Studies", "Information Technology",
  "Social Sciences", "Fine Arts", "Architecture", "Design",
  "Management Studies", "Environmental Studies", "Other"
];

const RequestBook = () => {
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [formData, setFormData] = useState({
    bookName: '',
    class: '',
    stream: '',
    condition: 'New',
    phoneNumber: '',
    address: '',
    pin: '',
    comment: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem('userId');

    try {
      await addDoc(collection(db, 'bookRequests'), {
        ...formData,
        userId,
        timestamp: serverTimestamp(),
      });
    //   swal.fire({
    //     icon: 'success',
    //     title: 'Success!',
    //     text: 'Request submitted successfully. Our team will call you.',
    //     timer: 3000,
    //     showConfirmButton: false
    //   });
      swal('Success', 'Request submitted successfully. Our team will call you.', 'success');
      setFormData({
        bookName: '',
        class: '',
        stream: '',
        condition: 'New',
        phoneNumber: '',
        address: '',
        pin: '',
        comment: '',
      });
      setShowModal(false); // Close the modal on successful submission
    } catch (error) {
      console.error('Error adding book request: ', error);
      swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to submit request. Please try again.',
      });
    }
  };

  return (
    <>
      {/* Button to trigger modal */}
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Request a Book
      </Button>

      {/* Modal containing the form */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Request a Book</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Book Name</Form.Label>
              <Form.Control
                type="text"
                name="bookName"
                value={formData.bookName}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Class</Form.Label>
              <Form.Control
                type="text"
                name="class"
                value={formData.class}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Stream</Form.Label>
              <Form.Control
                as="select"
                name="stream"
                value={formData.stream}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a stream</option>
                {streamOptions.map((stream) => (
                  <option key={stream} value={stream}>{stream}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Condition</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="New"
                  name="condition"
                  value="New"
                  checked={formData.condition === 'New'}
                  onChange={handleInputChange}
                />
                <Form.Check
                  type="radio"
                  label="Used"
                  name="condition"
                  value="Used"
                  checked={formData.condition === 'Used'}
                  onChange={handleInputChange}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>PIN Code</Form.Label>
              <Form.Control
                type="text"
                name="pin"
                value={formData.pin}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="comment"
                value={formData.comment}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit Request
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RequestBook;