import React, { useEffect, useRef } from 'react';
import './FeatureShowcase.css';
import ViewBooks from "./ViewBooks";
import ViewCourses from "./ViewCourses";
import RequestBook from './BookRequest';
const FeatureShowcase = () => {
  const featuresRef = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('hc-feature-visible');
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    featuresRef.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      featuresRef.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);
 
  const features = [
    {
      title: "Post Used Books",
      description: "List and sell your used textbooks easily. Create a sustainable marketplace for affordable academic resources.",
      icon: "📚",
      component: ViewBooks,
    }
    ,
    {
      title: "Request a Book",
      description: "Student's can request a book and our team will find the book for you...",
      icon: "🤝",
      component: RequestBook,
    },
    {
      title: "Share Study Notes",
      description: "Turn your notes into valuable resources. Help peers while earning from your study materials.",
      icon: "📝",
      component: 'abc',
    },
    {
      title: "Bookstore Integration",
      description: "Connect with local bookstores. Browse and buy from their inventory directly through our platform.",
      icon: "🏪",
      component: ViewBooks,
    },
    {
      title: "Create Online Courses",
      description: "Share your knowledge through engaging online courses. Reach students globally and foster peer learning.",
      icon: "💻",
      component: ViewCourses,
    },
    {
      title: "Quality Ratings",
      description: "Choose from multiple condition options. Our rating system ensures fair pricing and transparency.",
      icon: "🏷️",
      component: ViewBooks,
    },
  ];

  return (
    <div className="hc-features-container">
      <div className="hc-features-content">
        <h2 className="hc-features-title">Explore Our Features</h2>
        <div className="hc-features-grid">
          {features.map((feature, index) => (
            <div
              key={index}
              className="hc-feature-block"
              ref={el => featuresRef.current[index] = el}
            >
              <div className="hc-feature-content">
                <div className="hc-feature-icon">{feature.icon}</div>
                <h3 className="hc-feature-heading">{feature.title}</h3>
                <p className="hc-feature-description">{feature.description}</p>
              </div>
              <div className="hc-features-content">
                <feature.component />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureShowcase;