import React, { useState, useEffect } from "react";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase"; // Assuming you have firebase setup
import './MyProfile.css'; // Create a separate CSS file for styling the card

const MyProfile = () => {
    const bookstoreId = localStorage.getItem('BookstoreId');
    const [bookstoreData, setBookstoreData] = useState({
        BookStoreName: "",
        address: "",
        distName: "",
        email: "",
        password: "",
        phone: "",
        profilePic: ""
    });

    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    // Fetch the bookstore data on component mount
    useEffect(() => {
        const fetchBookstoreData = async () => {
            try {
                const docRef = doc(db, "Bookstore", bookstoreId); // Assuming you have a collection named "Bookstore"
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setBookstoreData(docSnap.data());
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBookstoreData();
    }, [bookstoreId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBookstoreData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleEditToggle = () => {
        setIsEditing(true);  // Enable editing mode when the "Edit" button is clicked
    };

    const handleCancelEdit = () => {
        setIsEditing(false); // Exit editing mode when "Cancel" is clicked
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const docRef = doc(db, "Bookstore", bookstoreId);
            await updateDoc(docRef, bookstoreData);
            alert("Data updated successfully!");
            setIsEditing(false); // Exit editing mode after successful submission
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="profile-container">
            <div className="card">
                <div className="card-header">
                    <h2>Bookstore Profile</h2>
                </div>
                <div className="card-body">
                    <div className="profile-img">
                        <img src={bookstoreData.profilePic} alt="Profile" width="150" height="150" />
                    </div>

                    <form onSubmit={handleSubmit} className="profile-form">
                        <div className="form-group">
                            <label>BookStore Name</label>
                            <input
                                type="text"
                                name="BookStoreName"
                                value={bookstoreData.BookStoreName}
                                onChange={handleInputChange}
                                disabled={!isEditing}  // Disable input if not editing
                                className="form-control"
                            />
                        </div>

                        <div className="form-group">
                            <label>Address</label>
                            <input
                                type="text"
                                name="address"
                                value={bookstoreData.address}
                                onChange={handleInputChange}
                                disabled={!isEditing}  // Disable input if not editing
                                className="form-control"
                            />
                        </div>

                        <div className="form-group">
                            <label>District Name</label>
                            <input
                                type="text"
                                name="distName"
                                value={bookstoreData.distName}
                                onChange={handleInputChange}
                                disabled={!isEditing}  // Disable input if not editing
                                className="form-control"
                            />
                        </div>

                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                name="email"
                                value={bookstoreData.email}
                                onChange={handleInputChange}
                                disabled={!isEditing}  // Disable input if not editing
                                className="form-control"
                            />
                        </div>

                        <div className="form-group">
                            <label>Password</label>
                            <input
                                type="password"
                                name="password"
                                value={bookstoreData.password}
                                onChange={handleInputChange}
                                disabled={!isEditing}  // Disable input if not editing
                                className="form-control"
                            />
                        </div>

                        <div className="form-group">
                            <label>Phone</label>
                            <input
                                type="text"
                                name="phone"
                                value={bookstoreData.phone}
                                onChange={handleInputChange}
                                disabled={!isEditing}  // Disable input if not editing
                                className="form-control"
                            />
                        </div>

                        {isEditing ? (
                            <>
                                <button type="submit" className="btn btn-success">
                                    Save Changes
                                </button>
                                <button type="button" onClick={handleCancelEdit} className="btn btn-secondary">
                                    Cancel
                                </button>
                            </>
                        ) : (
                            <button type="button" onClick={handleEditToggle} className="btn btn-primary">
                                Edit
                            </button>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default MyProfile;