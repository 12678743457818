import React, { useState } from 'react';
import { Tabs, Tab, Form, Button } from 'react-bootstrap';
import { collection, addDoc } from 'firebase/firestore';
import { db, storage } from '../firebase'; // Adjust this import based on your Firebase setup
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import swal from 'sweetalert';

const CreatePost = () => {
    const [key, setKey] = useState('uploadBooks');
    const [isUploading, setIsUploading] = useState(false);
    const [bookForm, setBookForm] = useState({
        bookName: '',
        authorName: '',
        className: '',
        stream: '',
        bookCondition: '',
        publishYear: '',
        price: '',
        sellingPrice: '',
        picture1: null,
        picture2: null,
        picture3: null
    });
    const [novelForm, setNovelForm] = useState({
        novelName: '',
        authorName: '',
        category: '',
        stream: '', // New field for stream in novels
        description: '',
        price: '',
        sellingPrice: '',
        picture1: null,
        picture2: null,
        picture3: null
    });

    const handleBookChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            const file = files[0];
            if (file && ['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
                setBookForm(prev => ({ ...prev, [name]: file }));
            } else {
                swal('Error', 'Please upload only PNG, JPG, or JPEG images.', 'error');
            }
        } else {
            setBookForm(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleNovelChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            const file = files[0];
            if (file && ['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
                setNovelForm(prev => ({ ...prev, [name]: file }));
            } else {
                swal('Error', 'Please upload only PNG, JPG, or JPEG images.', 'error');
            }
        } else {
            setNovelForm(prev => ({ ...prev, [name]: value }));
        }
    };

    const uploadImage = async (file) => {
        if (!file) return null;
        const storageRef = ref(storage, `images/${Date.now()}_${file.name}`);
        await uploadBytes(storageRef, file);
        return getDownloadURL(storageRef);
    };

    const uploadBook = async (e) => {
        e.preventDefault();
        if (!bookForm.bookName || !bookForm.bookCondition || !bookForm.stream) {
            swal('Error', 'Book name, condition, and stream are mandatory fields', 'error');
            return;
        }
        if (!bookForm.picture1 || !bookForm.picture2) {
            swal('Error', 'Please upload at least 2 pictures', 'error');
            return;
        }
        setIsUploading(true);
        try {
            const bookStoreId = localStorage.getItem('BookstoreId');
            const bookStoreName = localStorage.getItem('BookStoreName');
            
            const picture1Url = await uploadImage(bookForm.picture1);
            const picture2Url = await uploadImage(bookForm.picture2);
            const picture3Url = await uploadImage(bookForm.picture3);

            await addDoc(collection(db, 'Posts'), {
                ...bookForm,
                picture1: picture1Url,
                picture2: picture2Url,
                picture3: picture3Url,
                bookStoreId,
                bookStoreName,
                isBook: 1,
                timestamp: new Date()
            });
            setIsUploading(false);
            swal('Success', 'Book uploaded successfully', 'success');
            setBookForm({
                bookName: '',
                authorName: '',
                className: '',
                stream: '',
                bookCondition: '',
                publishYear: '',
                price: '',
                sellingPrice: '',
                picture1: null,
                picture2: null,
                picture3: null
            });
        } catch (error) {
            setIsUploading(false);
            swal('Error', 'Failed to upload book', 'error');
            console.error('Error uploading book:', error);
        }
    };

    const uploadNovel = async (e) => {
        e.preventDefault();
        if (!novelForm.novelName || !novelForm.authorName || !novelForm.category || !novelForm.description || !novelForm.stream) {
            swal('Error', 'All fields including stream are mandatory for novels', 'error');
            return;
        }
        if (!novelForm.picture1 || !novelForm.picture2) {
            swal('Error', 'Please upload at least 2 pictures', 'error');
            return;
        }
        setIsUploading(true);
        try {
            const bookStoreId = localStorage.getItem('BookstoreId');
            const bookStoreName = localStorage.getItem('BookStoreName');

            const picture1Url = await uploadImage(novelForm.picture1);
            const picture2Url = await uploadImage(novelForm.picture2);
            const picture3Url = await uploadImage(novelForm.picture3);

            await addDoc(collection(db, 'Posts'), {
                ...novelForm,
                picture1: picture1Url,
                picture2: picture2Url,
                picture3: picture3Url,
                bookStoreId,
                bookStoreName,
                isNovel: 1,
                timestamp: new Date()
            });
            setIsUploading(false);
            swal('Success', 'Novel uploaded successfully', 'success');
            setNovelForm({
                novelName: '',
                authorName: '',
                category: '',
                stream: '',
                description: '',
                price: '',
                sellingPrice: '',
                picture1: null,
                picture2: null,
                picture3: null
            });
        } catch (error) {
            setIsUploading(false);
            swal('Error', 'Failed to upload novel', 'error');
            console.error('Error uploading novel:', error);
        }
    };
    const streamOptions = [
        "Arts", "Science", "Commerce", "Humanities", "Engineering",
        "Medical", "Law", "Business Studies", "Information Technology",
        "Social Sciences", "Fine Arts", "Architecture", "Design",
        "Management Studies", "Environmental Studies", "Other"
    ];
    return (
        <div className="container mt-4">
            <h2 className="text-center mb-4">Upload Books / Novels</h2>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="uploadBooks" title="Upload Books">
                    <Form onSubmit={uploadBook}>
                        <Form.Group className="mb-3">
                            <Form.Label>Book Name *</Form.Label>
                            <Form.Control type="text" name="bookName" value={bookForm.bookName} onChange={handleBookChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Author Name</Form.Label>
                            <Form.Control type="text" name="authorName" value={bookForm.authorName} onChange={handleBookChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Class Name</Form.Label>
                            <Form.Control type="text" name="className" value={bookForm.className} onChange={handleBookChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Stream *</Form.Label>
                            <Form.Select name="stream" value={bookForm.stream} onChange={handleBookChange} required>
                                <option value="">Select Stream</option>
                                {streamOptions.map((option, index) => (
                                    <option key={index} value={option}>{option}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Book Condition *</Form.Label>
                            <Form.Select name="bookCondition" value={bookForm.bookCondition} onChange={handleBookChange} required>
                                <option value="">Select Condition</option>
                                <option value="New Book(Brand New)">New Book (Brand New)</option>
                                <option value="Superb(used)">Superb (used)</option>
                                <option value="Good(used)">Good (used)</option>
                                <option value="Poor(Used)">Poor (Used)</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Publish Year</Form.Label>
                            <Form.Select name="publishYear" value={bookForm.publishYear} onChange={handleBookChange}>
                                <option value="">Select Year</option>
                                {Array.from({ length: 26 }, (_, i) => 2000 + i).map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Original Price</Form.Label>
                            <Form.Control type="number" name="price" value={bookForm.price} onChange={handleBookChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Sell Price</Form.Label>
                            <Form.Control type="number" name="sellingPrice" value={bookForm.sellingPrice} onChange={handleBookChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Upload Pictures (2-3 required) *</Form.Label>
                            <Form.Control type="file" name="picture1" onChange={handleBookChange} accept="image/*" required className="mb-2" />
                            <Form.Control type="file" name="picture2" onChange={handleBookChange} accept="image/*" required className="mb-2" />
                            <Form.Control type="file" name="picture3" onChange={handleBookChange} accept="image/*" className="mb-2" />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={isUploading}>
                            {isUploading ? 'Uploading...' : 'Upload Book'}
                        </Button>
                    </Form>
                </Tab>
                <Tab eventKey="uploadNovels" title="Upload Novels">
                    <Form onSubmit={uploadNovel}>
                        <Form.Group className="mb-3">
                            <Form.Label>Novel Name *</Form.Label>
                            <Form.Control type="text" name="novelName" value={novelForm.novelName} onChange={handleNovelChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Author Name *</Form.Label>
                            <Form.Control type="text" name="authorName" value={novelForm.authorName} onChange={handleNovelChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Category *</Form.Label>
                            <Form.Control type="text" name="category" value={novelForm.category} onChange={handleNovelChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Stream *</Form.Label>
                            <Form.Select name="stream" value={novelForm.stream} onChange={handleNovelChange} required>
                                <option value="">Select Stream</option>
                                {streamOptions.map((option, index) => (
                                    <option key={index} value={option}>{option}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Novel Condition *</Form.Label>
                            <Form.Select name="novelCondition" value={novelForm.novelCondition} onChange={handleNovelChange} required>
                                <option value="">Select Condition</option>
                                <option value="New Book(Brand New)">New Book (Brand New)</option>
                                <option value="Superb(used)">Superb (used)</option>
                                <option value="Good(used)">Good (used)</option>
                                <option value="Poor(Used)">Poor (Used)</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Publish Year</Form.Label>
                            <Form.Select name="publishYear" value={novelForm.publishYear} onChange={handleNovelChange}>
                                <option value="">Select Year</option>
                                {Array.from({ length: 26 }, (_, i) => 2000 + i).map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Original Price</Form.Label>
                            <Form.Control type="number" name="price" value={novelForm.price} onChange={handleNovelChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Sell Price</Form.Label>
                            <Form.Control type="number" name="sellingPrice" value={novelForm.sellingPrice} onChange={handleNovelChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description *</Form.Label>
                            <Form.Control as="textarea" rows={3} name="description" value={novelForm.description} onChange={handleNovelChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Upload Pictures (2-3 required) *</Form.Label>
                            <Form.Control type="file" name="picture1" onChange={handleNovelChange} accept="image/*" required className="mb-2" />
                            <Form.Control type="file" name="picture2" onChange={handleNovelChange} accept="image/*" required className="mb-2" />
                            <Form.Control type="file" name="picture3" onChange={handleNovelChange} accept="image/*" className="mb-2" />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={isUploading}>
                            {isUploading ? 'Uploading...' : 'Upload Novel'}
                        </Button>
                    </Form>
                </Tab>
            </Tabs>
            {isUploading && (
                <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
                    <div className="bg-white p-4 rounded">
                        <p className="mb-0">Uploading... Please wait</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreatePost;