import React from 'react';

const ImageCompressor = ({ file, maxWidth = 1024, maxHeight = 1024, quality = 0.7, onCompress }) => {
  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const compressedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            resolve(compressedFile);
          }, 'image/jpeg', quality);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  React.useEffect(() => {
    const compress = async () => {
      if (file) {
        const compressedFile = await compressImage(file);
        onCompress(compressedFile);
      }
    };
    compress();
  }, [file, maxWidth, maxHeight, quality, onCompress]);

  return null; // This component doesn't render anything
};

export default ImageCompressor;