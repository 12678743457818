import React from 'react';
import a from  '../pics/amio.jpeg';
import b from  '../pics/sk.jpeg';
const TestimonialCards = () => {
  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "Marketing Director",
      content: "This product has revolutionized our workflow. It's intuitive, powerful, and has saved us countless hours. I couldn't imagine running our campaigns without it now.",
      imageUrl: "../pics/a.png"
    },
    {
      name: "Michael Chen",
      role: "Software Engineer",
      content: "As a developer, I'm always skeptical of new tools. But this one has truly impressed me. The API is well-documented, the performance is top-notch, and the support team is incredibly responsive.",
      imageUrl: "/api/placeholder/150/150"
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">Meet our Team</h2>
      <div class="card mb-3" >
        <div class="row g-0">
            <div class="col-md-4">
            <img src={b} style={{maxWidth:"200px", height:"200px"}} class="img-fluid rounded-start"  alt="..."/>
            </div>
            <div class="col-md-8">
            <div class="card-body">
                <h5 class="card-title">Sarif Khan</h5>
                <p class="card-text"> "I hope this platform, StudyHubs, serves as a valuable resource, empowering students to achieve their full potential and succeed in their educational endeavors.”</p>
                    E-mail: sarifkhanngn02@gmail.com <br></br>
                    Phone: 9365919522
                <p class="card-text"><small class="text-muted">Software Engineer & Tech Lead(Founder)</small></p>
            </div>
            </div>
        </div>
    </div>
        <div class="card mb-3" >
        <div class="row g-0">
            <div class="col-md-4">
            <img src={a} style={{maxWidth:"200px", height:"200px"}} class="img-fluid rounded-start" alt="..."/>
            </div>
            <div class="col-md-8">
            <div class="card-body">
                <h5 class="card-title">Amiyo Senapati</h5>
                <p class="card-text">"As the Marketing Director, my focus is on ensuring that StudyHubs reaches every student who can benefit from it. We’re committed to creating a platform that not only provides valuable academic resources but also fosters a supportive community for students to thrive. Our mission is to connect, inspire, and empower students across all educational levels."</p>
                <p class="card-text"><small class="text-muted">Marketing Director(Founder)</small></p>
            </div>
            </div>
        </div>
        </div>
    </div>
  );
};

export default TestimonialCards;