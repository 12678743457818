import React from "react";
import img1 from '../pics/a.png';
import img2 from '../pics/b.png';
import img3 from '../pics/c.png';
import img4 from '../pics/d.png';

function Carousel() {
    return (
            <div className="row align-items-center text-white bg-dark">
                <div className="col-lg-6 col-md-12 mb-2 mb-lg-0">
                    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner" >
                            <div className="carousel-item active">
                                <img src={img1} className="d-block w-100 h-100" alt="Car 1" style={{ objectFit: "cover" }} />
                            </div>
                            <div className="carousel-item">
                                <img src={img3} className="d-block w-100 h-100" alt="Car 2" style={{ objectFit: "cover" }} />
                            </div>
                            <div className="carousel-item">
                                <img src={img2} className="d-block w-100 h-100" alt="Car 3" style={{ objectFit: "cover" }} />
                            </div>
                            <div className="carousel-item">
                                <img src={img4} className="d-block w-100 h-100" alt="Car 4" style={{ objectFit: "cover" }} />
                            </div>
    
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12">
                    <h3 className="text-center text-lg-start">
                        Welcome to StudyHub!
                    </h3>
                    <p className="text-center text-lg-start">
                    StudyHub is a dynamic online platform designed to bridge the gap between students and sellers in the educational marketplace. This innovative website allows students to read and purchase a diverse range of books and novels, as well as access valuable study materials and notes tailored to their academic needs. For those looking to sell, StudyHub provides an easy-to-use interface where users can post listings for books, novels, and courses, allowing them to share their knowledge and resources with others.   </p>
                </div>
            </div>
  
    );
}

export default Carousel;