import React, { useState } from "react";
import './Tabs.css';
import Carousel from "./Carousel";
import ViewBooks from "./ViewBooks";
import ViewNovels from "./ViewNovels";
import ViewNotes from "./ViewNotes";
import ViewCourse from "./ViewCourses";
import FeatureShowcase from "./FeatureShowcase";
const TabsComponent = () => {
  const [activeTab, setActiveTab] = useState("home");

  const tabData = [
    { id: "home", label: "Home" },
    { id: "books", label: "Books" },
    { id: "novels", label: "Novels" },
    { id: "notes", label: "Note's" },
    { id: "courses", label: "Courses" },
  ];

  return (
    <div className="tabs-container">
      <div className="tabs-list">
        {tabData.map((tab) => (
          <button
            key={tab.id}
            className={`tab-button ${activeTab === tab.id ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      
      <div className="tab-content">
        {activeTab === "home" && (
          <>
            <Carousel />
            <FeatureShowcase/>
            <ViewNovels/>
            <ViewNotes/>
          </>
        )}
        {activeTab === "books" && (
          <div>
            <h2 className="section-title">Books</h2>
            <ViewBooks />
          </div>
        )}
        {activeTab === "novels" && (
          <div>
            <h2 className="section-title">Novels</h2>
            <ViewNovels />
          </div>
        )}
        {activeTab === "notes" && (
          <div>
            <h2 className="section-title">Notes</h2>
            <ViewNotes />
          </div>
        )}
        {activeTab === "courses" && (
          <div>
            <h2 className="section-title">Courses</h2>
            <ViewCourse />
          </div>
        )}
      </div>
    </div>
  );
};

export default TabsComponent;