import React, { useEffect } from 'react';

const Security = ({ children }) => {
  useEffect(() => {
    const disableCopyPaste = (e) => {
      e.preventDefault();
      return false;
    };

    const disableScreenCapture = () => {
      document.addEventListener('keyup', (e) => {
        if (e.key === 'PrintScreen') {
          navigator.clipboard.writeText('');
        }
      });

      document.addEventListener('keydown', (e) => {
        if (e.ctrlKey && e.key === 'p') {
          e.cancelBubble = true;
          e.preventDefault();
          e.stopImmediatePropagation();
        }
      });
    };

    const disableDevTools = () => {
      document.addEventListener('contextmenu', (e) => e.preventDefault());
      document.onkeydown = (e) => {
        if (e.keyCode === 123) return false;
        if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) return false;
        if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) return false;
        if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) return false;
        if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) return false;
      };
    };

    document.addEventListener('copy', disableCopyPaste);
    document.addEventListener('paste', disableCopyPaste);
    document.addEventListener('cut', disableCopyPaste);
    document.addEventListener('selectstart', disableCopyPaste);
    disableScreenCapture();
    disableDevTools();

    return () => {
      document.removeEventListener('copy', disableCopyPaste);
      document.removeEventListener('paste', disableCopyPaste);
      document.removeEventListener('cut', disableCopyPaste);
      document.removeEventListener('selectstart', disableCopyPaste);
    };
  }, []);

  return <>{children}</>;
};

export default Security;