import React, { useState, useEffect, useCallback } from 'react';
import { Card, Badge, Button, Modal, Form } from 'react-bootstrap';
import { collection, query, orderBy, limit, startAfter, getDocs, where, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import './ViewNotesAndNovels.css';

const ViewCourse = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [currentPdf, setCurrentPdf] = useState('');
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [comment, setComment] = useState('');
  const [courseComments, setCourseComments] = useState([]);
  const [purchaseForm, setPurchaseForm] = useState({
    name: '',
    email: '',
    phone: '',
    alternativePhone: '',
  });

  const navigate = useNavigate();
  const POSTS_PER_PAGE = 12;

  const fetchPosts = useCallback(async () => {
    if (!hasMore) return;

    setLoading(true);
    try {
      let postsQuery = query(
        collection(db, 'Posts'),
        where('isCourse', '==', 1),
        orderBy('timestamp', 'desc'),
        limit(POSTS_PER_PAGE)
      );

      if (lastVisible) {
        postsQuery = query(postsQuery, startAfter(lastVisible));
      }

      const querySnapshot = await getDocs(postsQuery);
      const newPosts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setPosts(prevPosts => {
        const updatedPosts = [...prevPosts, ...newPosts];
        return updatedPosts.filter((post, index, self) =>
          index === self.findIndex((t) => t.id === post.id)
        );
      });

      const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisible(lastVisibleDoc);
      setHasMore(querySnapshot.docs.length === POSTS_PER_PAGE);
    } catch (error) {
      console.error('Error fetching courses:', error);
    } finally {
      setLoading(false);
    }
  }, [lastVisible, hasMore]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const formatDate = (timestamp) => {
    if (timestamp?.toDate) {
      return timestamp.toDate().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
    return 'N/A';
  };

  const checkAuth = () => {
    const userId = localStorage.getItem('userId');
    return userId;
  };

  const handlePreview = (pdfUrl) => {
    setCurrentPdf(pdfUrl);
    setShowPdfModal(true);
  };

  const handleBuyClick = (course) => {
    if (!checkAuth()) {
      navigate('/login');
      return;
    }
    setCurrentCourse(course);
    setShowPurchaseModal(true);
  };

  const handleCommentClick = async (course) => {
    if (!checkAuth()) {
      navigate('/login');
      return;
    }
    setCurrentCourse(course);
    setShowCommentModal(true);

    try {
      const commentsQuery = query(
        collection(db, 'CourseComments'),
        where('courseId', '==', course.id),
        orderBy('timestamp', 'desc')
      );
      const commentsSnapshot = await getDocs(commentsQuery);
      const comments = commentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCourseComments(comments);
    } catch (error) {
      console.error('Error fetching comments:', error);
      swal('Error', 'Failed to load comments', 'error');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!comment.trim()) {
      swal('Error', 'Comment cannot be empty', 'error');
      return;
    }

    try {
      const userId = localStorage.getItem('userId');
      const commentData = {
        courseId: currentCourse.id,
        courseName: currentCourse.name,
        comment: comment,
        userId: userId,
        timestamp: new Date()
      };

      await addDoc(collection(db, 'CourseComments'), commentData);

      const commentsQuery = query(
        collection(db, 'CourseComments'),
        where('courseId', '==', currentCourse.id),
        orderBy('timestamp', 'desc')
      );
      const commentsSnapshot = await getDocs(commentsQuery);
      const updatedComments = commentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCourseComments(updatedComments);

      setComment('');
      swal('Success', 'Comment added successfully!', 'success');
    } catch (error) {
      console.error('Error adding comment:', error);
      swal('Error', 'Failed to add comment', 'error');
    }
  };

  const handlePurchaseSubmit = async (e) => {
    e.preventDefault();
    if (!checkAuth()) {
      navigate('/login');
      return;
    }

    try {
      const userId = localStorage.getItem('userId');
      const orderData = {
        courseId: currentCourse.id,
        courseName: currentCourse.name,
        price: currentCourse.price,
        buyerId: userId,
        sellerId: currentCourse.userId,
        timestamp: new Date(),
        ...purchaseForm,
      };

      await addDoc(collection(db, 'CourseOrders'), orderData);
      swal('Success', 'Course purchased successfully!', 'success');
      setShowPurchaseModal(false);
      setPurchaseForm({
        name: '',
        email: '',
        phone: '',
        alternativePhone: '',
      });
    } catch (error) {
      console.error('Error placing order:', error);
      swal('Error', 'Failed to place order', 'error');
    }
  };

  const PdfPreview = ({ pdfUrl }) => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (isMobile) {
      return (
        <div style={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true&rm=minimal`}
            width="100%"
            height="100%"
            style={{ border: 'none', position: 'absolute', top: 0, left: 0 }}
            title="PDF Preview"
            sandbox="allow-scripts allow-same-origin"
          />
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
            pointerEvents: 'none'
          }} />
        </div>
      );
    } else {
      return (
        <object
          data={`${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`}
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <p>Your browser does not support PDFs. Please try a different browser to view it.</p>
        </object>
      );
    }
  };

  if (loading && posts.length === 0) {
    return <div className="text-center mt-5">Loading courses...</div>;
  }

  return (
    <div className="vc-container">
      <div className="vc-scroll-wrapper">
        {posts.map((post) => (
          <div key={post.id} className="vc-card-wrapper">
            <Card className="vc-course-card">
              <div className="vc-image-wrapper">
                {post.coverImage && (
                  <img
                    className="vc-course-image"
                    src={post.coverImage}
                    alt={post.name}
                  />
                )}
                <Badge 
                  bg="primary"
                  className="vc-badge"
                >
                  Course
                </Badge>
              </div>
              <Card.Body>
                <Card.Title>{post.name}</Card.Title>
                <div className="vc-course-info">
                  <p>Instructor: {post.userName}</p>
                  <p>Class: {post.className || 'N/A'}</p>
                  <p>Category: {post.category}</p>
                </div>
                <div className="vc-price-info">
                  <p>
                    <span className="vc-selling-price">₹{post.price}</span>
                    {post.discount && (
                      <span className="vc-discount"> ({post.discount}% off)</span>
                    )}
                  </p>
                  <p className="vc-post-date">
                    Posted: {formatDate(post.timestamp)}
                  </p>
                </div>
                <div className="vc-button-group">
                  <Button variant="outline-primary" size="sm" onClick={() => handlePreview(post.pdf1)}>
                    Preview
                  </Button>
                  <Button variant="primary" size="sm" onClick={() => handleBuyClick(post)}>
                    Buy Course
                  </Button>
                  <Button variant="secondary" size="sm" onClick={() => handleCommentClick(post)}>
                    Comments
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>

      <Modal 
        show={showPdfModal} 
        onHide={() => setShowPdfModal(false)} 
        fullscreen={true}
        contentClassName="p-0"
        style={{ maxWidth: '100%', maxHeight: '100%', margin: 0 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>PDF Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <PdfPreview pdfUrl={currentPdf} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showCommentModal}
        onHide={() => {
          setShowCommentModal(false);
          setCourseComments([]);
          setComment('');
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comments - {currentCourse?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <h6>Add a Comment</h6>
            <Form onSubmit={handleCommentSubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Write your comment here..."
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit Comment
              </Button>
            </Form>
          </div>

          {courseComments.length > 0 && (
            <div>
              <h6>Existing Comments</h6>
              <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {courseComments.map((comment, index) => (
                  <Card key={index} className="mb-2">
                    <Card.Body>
                      <Card.Text>{comment.comment}</Card.Text>
                      <Card.Footer className="text-muted">
                        <small>
                          Posted on {formatDate(comment.timestamp)}
                        </small>
                      </Card.Footer>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showPurchaseModal} onHide={() => setShowPurchaseModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Purchase Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handlePurchaseSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={purchaseForm.name}
                onChange={(e) => setPurchaseForm({ ...purchaseForm, name: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={purchaseForm.email}
                onChange={(e) => setPurchaseForm({ ...purchaseForm, email: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={purchaseForm.phone}
                onChange={(e) => setPurchaseForm({ ...purchaseForm, phone: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Alternative Phone Number (Optional)</Form.Label>
              <Form.Control
                type="tel"
                name="alternativePhone"
                value={purchaseForm.alternativePhone}
                onChange={(e) => setPurchaseForm({ ...purchaseForm, alternativePhone: e.target.value })}
              />
            </Form.Group>
            {currentCourse && (
              <div className="mb-3">
                <h6>Course Details:</h6>
                <p className="mb-1"><strong>Name:</strong> {currentCourse.name}</p>
                <p className="mb-1"><strong>Price:</strong> ₹{currentCourse.price}</p>
                {currentCourse.discount && (
                  <p className="mb-1 text-danger">
                    <strong>Discount:</strong> {currentCourse.discount}%
                  </p>
                )}
              </div>
            )}
            <div className="d-flex justify-content-end">
            <Button variant="secondary" className="me-2" onClick={() => setShowPurchaseModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Confirm Purchase
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {hasMore && (
        <div className="load-more">
          <Button variant="outline-primary" onClick={fetchPosts}>
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default ViewCourse;