import React, { useState, useEffect, useCallback } from 'react';
import { Card, Badge, Button, Modal, Form, Carousel } from 'react-bootstrap';
import { collection, query, orderBy, limit, startAfter, getDocs, where, addDoc } from 'firebase/firestore';
import { InputGroup, Dropdown } from 'react-bootstrap';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import './ViewBooks.css';
const ViewBooks = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [currentBook, setCurrentBook] = useState(null);
  const [comment, setComment] = useState('');
  const [bookComments, setBookComments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('name');
  
  const [stream, setStream] = useState('');
  const [purchaseForm, setPurchaseForm] = useState({
    name: '',
    phone: '',
    phone2: '',
    quantity: 1,
    pin: '',
    address: '',
  });

  const navigate = useNavigate();
  const POSTS_PER_PAGE = 12;
// At the top-level, within `ViewBooks`, move `streamOptions` array declaration here
const streamOptions = [
  "Arts", "Science", "Commerce", "Humanities", "Engineering",
  "Medical", "Law", "Business Studies", "Information Technology",
  "Social Sciences", "Fine Arts", "Architecture", "Design",
  "Management Studies", "Environmental Studies"
];

const searchItems = async () => {
  setLoading(true);
  let q;

  // Adjust the query based on searchTerm or stream selected
  if (filterType === 'name') {
    q = query(
      collection(db, 'Posts'),
      where('bookName', '>=', searchTerm),
      where('bookName', '<=', searchTerm + '\uf8ff')
    );
  } else if (filterType === 'stream' && stream) {
    q = query(collection(db, 'Posts'), where('stream', '==', stream));
  } else {
    // Handle cases with no filter
    q = query(collection(db, 'Posts'));
  }

  try {
    const querySnapshot = await getDocs(q);
    const fetchedItems = [];
    querySnapshot.forEach((doc) => {
      fetchedItems.push({ id: doc.id, ...doc.data() });
    });
    setPosts(fetchedItems); // Updates the `posts` state here
  } catch (error) {
    console.error("Error fetching items: ", error);
  } finally {
    setLoading(false);
  }
};

// Ensure the `streamOptions` and `searchItems` are defined within the main component's scope
  const fetchPosts = useCallback(async () => {
    if (!hasMore) return;
  
    setLoading(true);
    try {
      let postsQuery = query(
        collection(db, 'Posts'),
        where('isBook', '==', 1),
        orderBy('timestamp', 'desc'),
        limit(POSTS_PER_PAGE)
      );
  
      // Apply the search term filter based on the filter type (name or stream)
      if (filterType === 'name' && searchTerm) {
        postsQuery = query(
          postsQuery,
          where('bookName', '>=', searchTerm),
          where('bookName', '<=', searchTerm + '\uf8ff')
        );
      } else if (filterType === 'stream' && stream) {
        postsQuery = query(postsQuery, where('stream', '==', stream));
      }
  
      if (lastVisible) {
        postsQuery = query(postsQuery, startAfter(lastVisible));
      }
  
      const querySnapshot = await getDocs(postsQuery);
      const newPosts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(prevPosts => {
        const updatedPosts = [...prevPosts, ...newPosts];
        return updatedPosts.filter((post, index, self) =>
          index === self.findIndex((t) => t.id === post.id)
        );
      });
  
      const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisible(lastVisibleDoc);
      setHasMore(querySnapshot.docs.length === POSTS_PER_PAGE);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  }, [lastVisible, hasMore, searchTerm, filterType, stream]);
  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const formatDate = (timestamp) => {
    if (timestamp?.toDate) {
      return timestamp.toDate().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
    return 'N/A';
  };

  const formatCommenterId = (commenterId) => {
    if (commenterId.length <= 8) return commenterId;
    return `${commenterId.substring(0, 8)}${'*'.repeat(commenterId.length - 8)}`;
  };

  const checkAuth = () => {
    const userId = localStorage.getItem('userId');
    const bookStoreId = localStorage.getItem('bookStoreId');
    return userId || bookStoreId;
  };

  const handleBuyClick = (book) => {
    if (!checkAuth()) {
      navigate('/login');
      return;
    }
    setCurrentBook(book);
    setShowPurchaseModal(true);
  };

  const handleCommentClick = async (book) => {
    if (!checkAuth()) {
      navigate('/login');
      return;
    }
    setCurrentBook(book);
    setShowCommentModal(true);

    try {
      const commentsQuery = query(
        collection(db, 'Comments'),
        where('bookId', '==', book.id),
        orderBy('timestamp', 'desc')
      );
      const commentsSnapshot = await getDocs(commentsQuery);
      const comments = commentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setBookComments(comments);
    } catch (error) {
      console.error('Error fetching comments:', error);
      swal('Error', 'Failed to load comments', 'error');
    }
  };

  const handlePurchaseSubmit = async (e) => {
    e.preventDefault();
    if (!checkAuth()) {
      navigate('/login');
      return;
    }

    try {
      const userId = localStorage.getItem('userId');
      const bookStoreId = localStorage.getItem('bookStoreId');
      const orderData = {
        bookId: currentBook.id,
        bookName: currentBook.bookName,
        price: currentBook.sellingPrice,
        buyerId: userId || bookStoreId,
        buyerType: userId ? 'user' : 'bookstore',
        sellerId: currentBook.bookStoreId,
        timestamp: new Date(),
        ...purchaseForm,
      };

      await addDoc(collection(db, 'Orders'), orderData);
      swal('Success', 'Book purchased successfully!', 'success');
      setShowPurchaseModal(false);
      setPurchaseForm({
        name: '',
        phone: '',
        phone2: '',
        quantity: 1,
        pin: '',
        address: '',
      });
    } catch (error) {
      console.error('Error placing order:', error);
      swal('Error', 'Failed to place order', 'error');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!comment.trim()) {
      swal('Error', 'Comment cannot be empty', 'error');
      return;
    }

    try {
      const userId = localStorage.getItem('userId');
      const bookStoreId = localStorage.getItem('bookStoreId');
      const commentData = {
        bookId: currentBook.id,
        bookName: currentBook.bookName,
        comment: comment,
        commenterId: userId || bookStoreId,
        commenterType: userId ? 'user' : 'bookstore',
        timestamp: new Date()
      };

      await addDoc(collection(db, 'Comments'), commentData);

      // Refresh comments
      const commentsQuery = query(
        collection(db, 'Comments'),
        where('bookId', '==', currentBook.id),
        orderBy('timestamp', 'desc')
      );
      const commentsSnapshot = await getDocs(commentsQuery);
      const updatedComments = commentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setBookComments(updatedComments);

      setComment('');
      swal('Success', 'Comment added successfully!', 'success');
    } catch (error) {
      console.error('Error adding comment:', error);
      swal('Error', 'Failed to add comment', 'error');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPurchaseForm(prev => ({ ...prev, [name]: value }));
  };

  if (loading && posts.length === 0) {
    return <div className="text-center mt-5">Loading posts...</div>;
  }

  return (
    
    <div className="vb-container">
      <div className="container mt-4">
      <h2 className="text-center mb-4">Search Books and Novels</h2>
      <Form className='searchBar'>
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            disabled={filterType === 'stream'}
          />
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary">
             Search by: {filterType === 'name' ? 'Name' : 'Stream'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setFilterType('name')}>Name</Dropdown.Item>
              <Dropdown.Item onClick={() => setFilterType('stream')}>Stream</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup>
        {filterType === 'stream' && (
          <Form.Group className="mb-3">
            <Form.Label>Select Stream</Form.Label>
            <Form.Select value={stream} onChange={(e) => setStream(e.target.value)}>
              <option value="">All Streams</option>
              {streamOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
        <Button variant="primary" onClick={searchItems}>Search</Button>
      </Form>
    </div>
      <div className="vb-scroll-wrapper">
        {posts.map((post) => (
          <div key={post.id} className="vb-card-wrapper">
            <Card className="vb-book-card">
              <div className="vb-image-wrapper">
                <Carousel>
                  {[post.picture1, post.picture2, post.picture3]
                    .filter(Boolean)
                    .map((pic, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={pic}
                          alt={`${post.bookName} - ${index + 1}`}
                        />
                      </Carousel.Item>
                    ))}
                </Carousel>
                <Badge 
                  bg="primary"
                  className="vb-badge"
                >
                  Book
                </Badge>
              </div>
              <Card.Body>
                <Card.Title className='vb-book-title'>{post.bookName}</Card.Title>
                <div className="vb-book-info">
                  <p>Author: {post.authorName}</p>
                  <p>Class: {post.className || 'N/A'}</p>
                  <p>Condition: {post.bookCondition}</p>
                  <p>Year: {post.publishYear || 'N/A'}</p>
                  <p className="vb-Category-price">Category/Stream: {post.stream || 'N/A'}</p>
                </div>
                <div className="vb-price-info">
                  <p>
                    <s>₹{post.price}</s>{' '}
                    <span className="vb-selling-price">₹{post.sellingPrice}</span>
                  </p>
                  <p className="vb-post-date">
                    Posted: {formatDate(post.timestamp)}
                  </p>
                </div>
                <div className="vb-button-group">
                  <Button variant="primary" size="sm" onClick={() => handleBuyClick(post)}>
                    Buy
                  </Button>
                  <Button variant="secondary" size="sm" onClick={() => handleCommentClick(post)}>
                    Comment
                  </Button>
                </div>
              </Card.Body>
              <Card.Footer>
                Posted by: {post.bookStoreName ? post.bookStoreName : post.userName}
              </Card.Footer>
            </Card>
          </div>
        ))}
      </div>


      <Modal
        show={showCommentModal}
        onHide={() => {
          setShowCommentModal(false);
          setBookComments([]);
          setComment('');
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comments - {currentBook?.bookName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <h6>Add a Comment</h6>
            <Form onSubmit={handleCommentSubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Write your comment here..."
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit Comment
              </Button>
            </Form>
          </div>

          {bookComments.length > 0 && (
            <div>
              <h6>Existing Comments</h6>
              <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {bookComments.map((comment, index) => (
                  <Card key={index} className="mb-2">
                    <Card.Body>
                      <Card.Text>{comment.comment}</Card.Text>
                      <Card.Footer className="text-muted">
                        <small>
                          By: {formatCommenterId(comment.commenterId)} ({comment.commenterType})<br />
                          {formatDate(comment.timestamp)}
                        </small>
                      </Card.Footer>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showPurchaseModal} onHide={() => setShowPurchaseModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Purchase Book</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handlePurchaseSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={purchaseForm.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={purchaseForm.phone}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Alternative Phone (Optional)</Form.Label>
              <Form.Control
                type="tel"
                name="phone2"
                value={purchaseForm.phone2}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                name="quantity"
                value={purchaseForm.quantity}
                onChange={handleInputChange}
                min="1"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>PIN Code</Form.Label>
              <Form.Control
                type="text"
                name="pin"
                value={purchaseForm.pin}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="address"
                value={purchaseForm.address}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Confirm Purchase
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {hasMore && (
        <div className="load-more">
          <Button variant="outline-primary" onClick={fetchPosts}>
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default ViewBooks;