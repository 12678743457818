import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../firebase'; // Adjust this import based on your Firebase setup
import { Form, Button, Card, Modal } from 'react-bootstrap';
import swal from 'sweetalert';

const AlterPosts = () => {
    const [posts, setPosts] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentPost, setCurrentPost] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        setIsLoading(true);
        const bookStoreId = localStorage.getItem('BookstoreId');
        const postsRef = collection(db, 'Posts');
        const q = query(postsRef, where('bookStoreId', '==', bookStoreId));
        
        try {
            const querySnapshot = await getDocs(q);
            const fetchedPosts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPosts(fetchedPosts);
        } catch (error) {
            console.error('Error fetching posts:', error);
            swal('Error', 'Failed to fetch posts', 'error');
        }
        setIsLoading(false);
    };

    const handleEdit = (post) => {
        setCurrentPost(post);
        setShowEditModal(true);
    };

    const handleDelete = async (post) => {
        const confirmed = await swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this post!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (confirmed) {
            setIsLoading(true);
            try {
                // Delete the document from Firestore
                await deleteDoc(doc(db, 'Posts', post.id));

                // Delete associated images from Storage
                if (post.picture1) await deleteObject(ref(storage, post.picture1));
                if (post.picture2) await deleteObject(ref(storage, post.picture2));
                if (post.picture3) await deleteObject(ref(storage, post.picture3));

                swal("Post deleted successfully", "", "success");
                fetchPosts(); // Refresh the posts list
            } catch (error) {
                console.error('Error deleting post:', error);
                swal('Error', 'Failed to delete post', 'error');
            }
            setIsLoading(false);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const postRef = doc(db, 'Posts', currentPost.id);
            
            // Handle file uploads if new images are selected
            const updatedPost = { ...currentPost };
            for (let i = 1; i <= 3; i++) {
                const pictureKey = `picture${i}`;
                if (currentPost[pictureKey] instanceof File) {
                    const imageUrl = await uploadImage(currentPost[pictureKey]);
                    updatedPost[pictureKey] = imageUrl;
                }
            }

            await updateDoc(postRef, updatedPost);
            swal('Success', 'Post updated successfully', 'success');
            setShowEditModal(false);
            fetchPosts(); // Refresh the posts list
        } catch (error) {
            console.error('Error updating post:', error);
            swal('Error', 'Failed to update post', 'error');
        }
        setIsLoading(false);
    };

    const uploadImage = async (file) => {
        if (!file) return null;
        const storageRef = ref(storage, `images/${Date.now()}_${file.name}`);
        await uploadBytes(storageRef, file);
        return getDownloadURL(storageRef);
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setCurrentPost(prev => ({ ...prev, [name]: files[0] }));
        } else {
            setCurrentPost(prev => ({ ...prev, [name]: value }));
        }
    };

    return (
        <div className="container mt-4">
            <h2 className="text-center mb-4">Manage Your Posts</h2>
            {isLoading ? (
                <div className="text-center">Loading...</div>
            ) : (
                posts.map(post => (
                    <Card key={post.id} className="mb-3">
                        <Card.Body>
                            <Card.Title>{post.isBook ? post.bookName : post.novelName}</Card.Title>
                            <Card.Text>
                                Author: {post.authorName}<br />
                                Price: {post.price}<br />
                                Selling Price: {post.sellingPrice}
                            </Card.Text>
                            <Button variant="primary" onClick={() => handleEdit(post)} className="me-2">Edit</Button>
                            <Button variant="danger" onClick={() => handleDelete(post)}>Delete</Button>
                        </Card.Body>
                    </Card>
                ))
            )}

            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleUpdate}>
                        <Form.Group className="mb-3">
                            <Form.Label>{currentPost?.isBook ? 'Book Name' : 'Novel Name'}</Form.Label>
                            <Form.Control 
                                type="text" 
                                name={currentPost?.isBook ? 'bookName' : 'novelName'} 
                                value={currentPost?.isBook ? currentPost.bookName : currentPost?.novelName} 
                                onChange={handleInputChange} 
                                required 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Author Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="authorName" 
                                value={currentPost?.authorName} 
                                onChange={handleInputChange} 
                                required 
                            />
                        </Form.Group>
                        {currentPost?.isBook && (
                            <Form.Group className="mb-3">
                                <Form.Label>Class Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="className" 
                                    value={currentPost?.className} 
                                    onChange={handleInputChange} 
                                />
                            </Form.Group>
                        )}
                        {currentPost?.isNovel && (
                            <Form.Group className="mb-3">
                                <Form.Label>Category</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="category" 
                                    value={currentPost?.category} 
                                    onChange={handleInputChange} 
                                    required 
                                />
                            </Form.Group>
                        )}
                        <Form.Group className="mb-3">
                            <Form.Label>{currentPost?.isBook ? 'Book' : 'Novel'} Condition</Form.Label>
                            <Form.Select 
                                name={currentPost?.isBook ? 'bookCondition' : 'novelCondition'} 
                                value={currentPost?.isBook ? currentPost.bookCondition : currentPost?.novelCondition} 
                                onChange={handleInputChange} 
                                required
                            >
                                <option value="">Select Condition</option>
                                <option value="New Book(Brand New)">New Book (Brand New)</option>
                                <option value="Superb(used)">Superb (used)</option>
                                <option value="Good(used)">Good (used)</option>
                                <option value="Poor(Used)">Poor (Used)</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Publish Year</Form.Label>
                            <Form.Select 
                                name="publishYear" 
                                value={currentPost?.publishYear} 
                                onChange={handleInputChange}
                            >
                                <option value="">Select Year</option>
                                {Array.from({ length: 26 }, (_, i) => 2000 + i).map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Original Price</Form.Label>
                            <Form.Control 
                                type="number" 
                                name="price" 
                                value={currentPost?.price} 
                                onChange={handleInputChange} 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Sell Price</Form.Label>
                            <Form.Control 
                                type="number" 
                                name="sellingPrice" 
                                value={currentPost?.sellingPrice} 
                                onChange={handleInputChange} 
                            />
                        </Form.Group>
                        {currentPost?.isNovel && (
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    rows={3} 
                                    name="description" 
                                    value={currentPost?.description} 
                                    onChange={handleInputChange} 
                                    required 
                                />
                            </Form.Group>
                        )}
                        <Form.Group className="mb-3">
                            <Form.Label>Update Pictures</Form.Label>
                            <Form.Control 
                                type="file" 
                                name="picture1" 
                                onChange={handleInputChange} 
                                accept="image/*" 
                                className="mb-2" 
                            />
                            <Form.Control 
                                type="file" 
                                name="picture2" 
                                onChange={handleInputChange} 
                                accept="image/*" 
                                className="mb-2" 
                            />
                            <Form.Control 
                                type="file" 
                                name="picture3" 
                                onChange={handleInputChange} 
                                accept="image/*" 
                                className="mb-2" 
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {isLoading ? 'Updating...' : 'Update Post'}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AlterPosts;

