import { HashRouter, Routes, Route, Link } from "react-router-dom";
import Myorder from "./orderlist";
import SellerProducts from "./sellersAds";
import AlterPosts from "./AlterPosts";
import MyProfile from "./adminMyProfile";
import CreatePost from "./CreatePost";
const AdminApp = () => {
    return (
        <HashRouter>
            <nav className="navbar navbar-expand-sm navbar-dark bg-dark p-3">
                <div className="container">
                    <a className="navbar-brand">
                        <i className="material-icons">menu_book</i>Book Store Dashboard
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="mynavbar">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item me-4">
                                <Link className="nav-link active" to="/AlterPosts"> 
                                    <i className="material-icons">storefront</i> My Posts 
                                </Link>
                            </li>
                            <li className="nav-item me-4">
                                <Link className="nav-link active" to="/orderlist"> 
                                    <i className="material-icons">receipt_long</i> Order List 
                                </Link>
                            </li>
                            <li className="nav-item me-4">
                                <Link className="nav-link active" to="/CreatePost"> 
                                    <i className="material-icons">menu_book</i> Upload Books/Novels
                                </Link>
                            </li>
                            <li className="nav-item me-4">
                                <Link className="nav-link active" to="/"> 
                                    <i className="material-icons">account_circle</i> My Profile 
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-warning" onClick={logout}>
                                    {localStorage.getItem("dealerName")} 
                                    <i className="material-icons">power_settings_new</i> Logout 
                                </Link>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </nav>

            <Routes>
                <Route exact path="/orderlist" element={<Myorder />} />
                <Route exact path="/CreatePost" element={<CreatePost />} />
                <Route exact path="/AlterPosts" element={<AlterPosts />} />
                <Route exact path="/" element={<MyProfile />} />
            </Routes>
        </HashRouter>
    );
}

export default AdminApp;

const logout = () => {
    localStorage.clear();
    window.location.reload();
    window.location.href = '/';
}