import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, Tabs, Tab } from 'react-bootstrap';
import { collection, query, where, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../firebase';
import swal from 'sweetalert';

const AlterAllPosts = () => {
    const [userPosts, setUserPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingPost, setEditingPost] = useState(null);
    const [activeTab, setActiveTab] = useState('books');
    const userId = localStorage.getItem('userId');
    const [newPdfFiles, setNewPdfFiles] = useState({ pdf1: null, pdf2: null });

    useEffect(() => {
        fetchUserPosts();
    }, []);

    const fetchUserPosts = async () => {
        try {
            const postsRef = collection(db, 'Posts');
            const q = query(postsRef, where('userId', '==', userId));
            const querySnapshot = await getDocs(q);
            
            const posts = [];
            querySnapshot.forEach((doc) => {
                posts.push({ id: doc.id, ...doc.data() });
            });
            
            setUserPosts(posts);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching posts:', error);
            swal('Error', 'Failed to fetch your posts', 'error');
            setLoading(false);
        }
    };

    const handleEdit = (post) => {
        setEditingPost(post);
        setNewPdfFiles({ pdf1: null, pdf2: null });
        setShowEditModal(true);
    };

    const handleDelete = async (post) => {
        const willDelete = await swal({
            title: "Are you sure?",
            text: `Do you want to delete this ${getPostType(post)}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (willDelete) {
            try {
                await deleteDoc(doc(db, 'Posts', post.id));
                
                // Delete images and PDFs from storage
                const filesToDelete = [
                    post.picture1, post.picture2, post.picture3,
                    post.pdf1, post.pdf2
                ].filter(Boolean);
                
                for (const fileUrl of filesToDelete) {
                    const fileRef = ref(storage, fileUrl);
                    await deleteObject(fileRef).catch(error => console.error('Error deleting file:', error));
                }

                swal("Post deleted successfully!", {
                    icon: "success",
                });
                fetchUserPosts();
            } catch (error) {
                console.error('Error deleting post:', error);
                swal('Error', 'Failed to delete post', 'error');
            }
        }
    };

    const uploadFile = async (file, oldFileUrl = null) => {
        if (!file) return oldFileUrl;

        try {
            // Delete old file if it exists
            if (oldFileUrl) {
                const oldFileRef = ref(storage, oldFileUrl);
                await deleteObject(oldFileRef).catch(error => console.error('Error deleting old file:', error));
            }

            const fileRef = ref(storage, `uploads/${Date.now()}_${file.name}`);
            await uploadBytes(fileRef, file);
            return await getDownloadURL(fileRef);
        } catch (error) {
            console.error('Error uploading file:', error);
            throw error;
        }
    };

    const handleUpdatePost = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const updatedPost = { ...editingPost };

            // Handle PDF uploads
            if (newPdfFiles.pdf1) {
                updatedPost.pdf1 = await uploadFile(newPdfFiles.pdf1, editingPost.pdf1);
            }
            if (newPdfFiles.pdf2) {
                updatedPost.pdf2 = await uploadFile(newPdfFiles.pdf2, editingPost.pdf2);
            }

            const postRef = doc(db, 'Posts', editingPost.id);
            await updateDoc(postRef, updatedPost);
            
            swal('Success', 'Post updated successfully', 'success');
            setShowEditModal(false);
            fetchUserPosts();
        } catch (error) {
            console.error('Error updating post:', error);
            swal('Error', 'Failed to update post', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleEditFormChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            if (name === 'pdf1' || name === 'pdf2') {
                setNewPdfFiles(prev => ({ ...prev, [name]: files[0] }));
            }
        } else {
            setEditingPost(prev => ({ ...prev, [name]: value }));
        }
    };

    const getPostType = (post) => {
        if (post.isBook) return 'book';
        if (post.isNovel) return 'novel';
        if (post.isNote) return 'note';
        if (post.isCourse) return 'course material';
        return 'post';
    };

    const renderEditForm = () => {
        if (!editingPost) return null;

        const postType = getPostType(editingPost);
        const isFileType = editingPost.isNote || editingPost.isCourse;

        return (
            <Form onSubmit={handleUpdatePost}>
                <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={editingPost.name || editingPost.bookName || editingPost.novelName}
                        onChange={handleEditFormChange}
                        required
                    />
                </Form.Group>
                {!isFileType && (
                    <Form.Group className="mb-3">
                        <Form.Label>Author Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="authorName"
                            value={editingPost.authorName}
                            onChange={handleEditFormChange}
                        />
                    </Form.Group>
                )}
                {isFileType && (
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>College Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="collegeName"
                                value={editingPost.collegeName}
                                onChange={handleEditFormChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Class Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="className"
                                value={editingPost.className}
                                onChange={handleEditFormChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Select
                                name="category"
                                value={editingPost.category}
                                onChange={handleEditFormChange}
                            >
                                <option value="">Select Category</option>
                                <option value="HSLC (SEBA)">HSLC (SEBA)</option>
                                <option value="Higher Secondary (Science)">Higher Secondary (Science)</option>
                                <option value="Higher Secondary (Commerce)">Higher Secondary (Commerce)</option>
                                <option value="Higher Secondary (Arts)">Higher Secondary (Arts)</option>
                                <option value="Other">Other</option>
                            </Form.Select>
                        </Form.Group>
                        {editingPost.category === 'Other' && (
                            <Form.Group className="mb-3">
                                <Form.Label>Other Category</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="otherCategory"
                                    value={editingPost.otherCategory}
                                    onChange={handleEditFormChange}
                                />
                            </Form.Group>
                        )}
                        <Form.Group className="mb-3">
                            <Form.Label>PDF 1</Form.Label>
                            <Form.Control
                                type="file"
                                name="pdf1"
                                onChange={handleEditFormChange}
                                accept=".pdf"
                            />
                            {editingPost.pdf1 && <small>Current file: {editingPost.pdf1.split('/').pop()}</small>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>PDF 2</Form.Label>
                            <Form.Control
                                type="file"
                                name="pdf2"
                                onChange={handleEditFormChange}
                                accept=".pdf"
                            />
                            {editingPost.pdf2 && <small>Current file: {editingPost.pdf2.split('/').pop()}</small>}
                        </Form.Group>
                    </>
                )}
                <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="description"
                        value={editingPost.description}
                        onChange={handleEditFormChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                        type="text"
                        name="price"
                        value={editingPost.price}
                        onChange={handleEditFormChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Discount</Form.Label>
                    <Form.Control
                        type="number"
                        name="discount"
                        value={editingPost.discount}
                        onChange={handleEditFormChange}
                    />
                </Form.Group>
                <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? 'Updating...' : 'Update Post'}
                </Button>
            </Form>
        );
    };

    const renderPostCard = (post) => {
        const postType = getPostType(post);
        return (
            <Card>
                <Card.Body>
                    <Card.Title>{post.name || post.bookName || post.novelName}</Card.Title>
                    <Card.Text>
                        {post.authorName && <>Author: {post.authorName}<br /></>}
                        {post.collegeName && <>College: {post.collegeName}<br /></>}
                        {post.className && <>Class: {post.className}<br /></>}
                        {post.category && <>Category: {post.category}<br /></>}
                        Price: ₹{post.price}<br />
                        {post.discount && <>Discount: {post.discount}%</>}
                    </Card.Text>
                    {(post.pdf1 || post.pdf2) && (
                        <div className="mb-2">
                            {post.pdf1 && (
                                <Button href={post.pdf1} target="_blank" variant="link" className="p-0 me-2">
                                    View PDF 1
                                </Button>
                            )}
                            {post.pdf2 && (
                                <Button href={post.pdf2} target="_blank" variant="link" className="p-0">
                                    View PDF 2
                                </Button>
                            )}
                        </div>
                    )}
                    <Button variant="primary" onClick={() => handleEdit(post)} className="me-2">
                        Edit
                    </Button>
                    <Button variant="danger" onClick={() => handleDelete(post)}>
                        Delete
                    </Button>
                </Card.Body>
            </Card>
        );
    };

    return (
        <div className="container mt-4">
            <h2 className="text-center mb-4">Manage Your Posts</h2>
            <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                className="mb-3"
            >
                <Tab eventKey="books" title="Books">
                    <div className="row">
                        {userPosts.filter(post => post.isBook).map(post => (
                            <div key={post.id} className="col-md-6 mb-4">
                                {renderPostCard(post)}
                            </div>
                        ))}
                    </div>
                </Tab>
                <Tab eventKey="novels" title="Novels">
                    <div className="row">
                        {userPosts.filter(post => post.isNovel).map(post => (
                            <div key={post.id} className="col-md-6 mb-4">
                                {renderPostCard(post)}
                            </div>
                        ))}
                    </div>
                </Tab>
                <Tab eventKey="notes" title="Notes">
                    <div className="row">
                        {userPosts.filter(post => post.isNote).map(post => (
                            <div key={post.id} className="col-md-6 mb-4">
                                {renderPostCard(post)}
                            </div>
                        ))}
                    </div>
                </Tab>
                <Tab eventKey="courses" title="Course Materials">
                    <div className="row">
                        {userPosts.filter(post => post.isCourse).map(post => (
                            <div key={post.id} className="col-md-6 mb-4">
                                {renderPostCard(post)}
                            </div>
                        ))}
                    </div>
                </Tab>
            </Tabs>

            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit {editingPost ? getPostType(editingPost) : 'Post'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {renderEditForm()}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AlterAllPosts;