import { useState } from "react";
import swal from "sweetalert";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { Link } from "react-router-dom";

const UserSignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [className, setClassName] = useState("");
    const [collegeName, setCollegeName] = useState("");
    const [studentIdType, setStudentIdType] = useState("");
    const [studentId, setStudentId] = useState("");

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validatePassword = (password) => {
        return password.length >= 6;
    };

    const validatePhone = (phone) => {
        const re = /^\d{10}$/;
        return re.test(phone);
    };

    const validateRequiredFields = () => {
        const requiredFields = [
            { name: "Name", value: name },
            { name: "Class Name", value: className },
            { name: "College Name", value: collegeName },
            { name: "Mobile Number", value: phone },
            { name: "Email", value: email },
            { name: "Password", value: password }
        ];

        const emptyFields = requiredFields.filter(field => !field.value.trim());

        if (emptyFields.length > 0) {
            const missingFields = emptyFields.map(field => field.name).join(", ");
            swal("Required Fields Missing", `Please enter the following required fields: ${missingFields}`, "warning");
            return false;
        }

        return true;
    };

    const gologin = async () => {
        if (!validateRequiredFields()) {
            return;
        }

        if (!validateEmail(email)) {
            swal("Invalid Input", "Please enter a valid e-mail address", "warning");
            return;
        }

        if (!validatePassword(password)) {
            swal("Invalid Input", "Password must be at least 6 characters long", "warning");
            return;
        }

        if (!validatePhone(phone)) {
            swal("Invalid Input", "Please enter a valid 10-digit phone number", "warning");
            return;
        }

        // If Student ID Type is selected, ensure Student ID is provided
        if (studentIdType && !studentId) {
            swal("Invalid Input", `Please enter your ${studentIdType} ID`, "warning");
            return;
        }

        try {
            const userData = {
                email,
                password,
                phone,
                name,
                className,
                collegeName
            };

            // Only add studentIdType and studentId to userData if they are provided
            if (studentIdType) {
                userData.studentIdType = studentIdType;
                userData.studentId = studentId;
            }

            const docRef = await addDoc(collection(db, "UsersIds"), userData);
            swal("Success", "User Registered Successfully", "success", { timer: 1500 });
            window.location.href = '/login';
        } catch (e) {
            console.error("Error adding document: ", e);
            swal("Error", "Could not register user", "error");
        }
    }

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                    <div className="card border-0 shadow-lg">
                        <div className="card-header bg-primary text-white">Student Sign Up</div>
                        <div className="card-body">
                            <div className="mb-3">
                                <label>Student Name *</label>
                                <input type="text" placeholder="Enter your Name" className="form-control" onChange={e => setName(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>Class Name *</label>
                                <input type="text" placeholder="Enter class Name" className="form-control" onChange={e => setClassName(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>College Name *</label>
                                <input type="text" placeholder="Enter your college Name" className="form-control" onChange={e => setCollegeName(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>Mobile Number *</label>
                                <input type="text" placeholder="Enter your 10 digit mobile number" className="form-control" onChange={e => setPhone(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>e-Mail Id *</label>
                                <input type="email" placeholder="yourmail@gmail.com" className="form-control" onChange={e => setEmail(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>Password *</label>
                                <input type="password" placeholder="Enter 6 digit password" className="form-control" onChange={e => setPassword(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>Student Id Type (Optional)</label>
                                <select className="form-control" onChange={e => setStudentIdType(e.target.value)}>
                                    <option value="">Select Student Id Type</option>
                                    <option value="Voter">Voter ID</option>
                                    <option value="PAN">PAN</option>
                                    <option value="HSLC">HSLC Certificate/Admit card number</option>
                                </select>
                            </div>
                            {studentIdType && (
                                <div className="mb-3">
                                    <label>Enter {studentIdType} ID</label>
                                    <input type="text" placeholder={`Enter your ${studentIdType} ID`} className="form-control" onChange={e => setStudentId(e.target.value)} />
                                </div>
                            )}
                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-danger m-2" onClick={gologin}>
                                User Sign Up <i className="fa fa-arrow-right"></i>
                            </button>
                            <div>
                                <Link to="/login">
                                    <b>Sign-In</b>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4"></div>
            </div>
        </div>
    )
}

export default UserSignUp;